import React, { useState, useEffect } from 'react';

// Material UI
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button'

// Custom Components
import Alert from '../../Components/Alert';
import Header from '../../Components/TopNav';
import Loader from '../../Components/Loader';
import Footer from '../../Components/Footer/index';
import CookieConsent from './Cookie-Consent';
import DocsModal from '../../Components/DocsModal';
import Timeline from './Timeline';
import WordSwap from './wordswap/index'

// Styles
import TypographyStyles from '../../assets/styles/TypographySyles';

// Assets
import AppImage from '../../assets/images/app_image.png'

// Material Icons
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

// React Helmet
import Helmet from "react-helmet/es/Helmet";

import $ from 'jquery';

const containerStyles = makeStyles((theme) => ({
    root: {
        padding: '0',
        maxWidth: '110rem',
        display: 'flex',
        marginTop: '64px',
        width: '100%'
    }
}))

const IpStyles = makeStyles((theme) => ({
    PseudoIp: {
        borderColor: 'lightgray',
        borderRadius: '0px',
        '&:focus': {
            borderColor: 'gray'
        },
        backgroundColor: 'whitesmoke',
        color: 'gray',
        width: '100%',
        padding: '11.5px !important'
    }
}))

const Index = () => {
    const [loader, setLoader] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverrity] = useState('')
    const [message, setMessage] = useState('')
    const docsContent = '<iframe width="560" height="720" src="https://www.youtube-nocookie.com/embed/rs2X0pbLPqw?vq=hd1080" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    const [docModal, setDocModal] = useState(false)
    const [dialog, setDialog] = useState(false)

    const [email, setEmail] = useState('')

    const containerClasses = containerStyles()
    const typographyClasses = TypographyStyles()
    const ipClassess = IpStyles()

    useEffect(() => {
        sessionStorage.removeItem('FreeTrialEnabled')
        sessionStorage.removeItem('DemoBooked')
        let attrs = {}
        if (window.location.href.indexOf('?') !== -1) {
            let url = window.location.href.split('?')
            let params = ''
            if (window.location.href.indexOf('&') !== -1) {
                params = url[1].split('&')
            }
            else {
                params = [url[1]]
            }
            for (let i = 0; i < params.length; i++) {
                if (params[i].indexOf('UserId') !== -1) {
                    attrs['UserId'] = params[i].split('=')[1]
                    sessionStorage.setItem('user_attrs', JSON.stringify(attrs))
                }
                else if (params[i].indexOf('CampaignId') !== -1) {
                    attrs['CampaignId'] = params[i].split('=')[1]
                    sessionStorage.setItem('user_attrs', JSON.stringify(attrs))
                }
                if (params[i].indexOf('demovideo') !== -1) {
                    $("html, body").animate({
                        scrollTop: $($('#demo-video-section')).offset().top - 40
                    });
                    // let top = document.getElementById('demo-video-section').offsetTop
                    // window.scrollTo(0, top-40)
                }
            }
        }
        else {
            sessionStorage.setItem('user_attrs', JSON.stringify(attrs))
        }
    }, [])

    const handleAlert = () => {
        setOpenAlert(false)
    }

    const handleDocsModal = (boolean) => {
        setDocModal(boolean)
    }

    // const checkIsLoggedinForSubscription = (planId) => {
    //     sessionStorage.setItem('subscribe', planId)
    //     if (sessionStorage.getItem('user')) {
    //         window.location.href = window.location.origin + "/#/user/addsubscriptions?planId=" + planId
    //     }
    //     else {
    //         window.location.href = window.location.origin + '/#/app/login'
    //     }
    // }

    const TryNow = ({ isHeroAction }) => {
        return (
            <Box p={1.2} pl={6} pr={6} style={{ cursor: 'pointer', backgroundImage: "linear-gradient(45deg, #ffeb3b, #ff9800)" }} mb={1} onClick={() => {
                if (isHeroAction) {
                    if (email === "") {
                        setMessage('Enter proper Email Id to trial the product')
                        setSeverrity('error')
                        setOpenAlert(true)
                    }
                    else {
                        sessionStorage.setItem('signupemail', email)
                        window.location.href = window.location.origin + "/#/app/signup"
                    }
                }
                else {
                    window.location.href = window.location.origin + "/#/app/signup"
                }
            }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography className={`${typographyClasses.bold} ${typographyClasses.h6} ${typographyClasses.root}`}>TRY NOW</Typography>
                </Box>
            </Box>
        )
    }

    const BookADemo = () => {
        return (
            <Box p={2} pl={8} pr={8} style={{ cursor: 'pointer', backgroundImage: "linear-gradient(45deg, #2196F3, #3F51B5)" }} mb={1} mt={1} onClick={() => {
                window.location.href = window.location.origin + "/#/contact-us"
            }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography className={`${typographyClasses.bold} ${typographyClasses.h5} ${typographyClasses.root}`}>BOOK A DEMO</Typography>
                </Box>
            </Box>
        )
    }

    const emailOnchange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <div>
            <Loader open={loader} />
            <Helmet>
                <title>Archeplay - Low Code API as s Service</title>
            </Helmet>
            <Alert openAlert={openAlert} severity={severity} message={message} handler={handleAlert} />
            <Header.MiniDrawer page="index" />
            <Container className={containerClasses.root}>
                <Box display="flex" flexDirection="column" style={{ width: '100%' }} className="main-block">
                    <Box style={{ padding: '1rem', maxWidth: '110rem', width: '100%', minHeight: window.innerWidth <= 768 ? 'auto' : 'calc(100vh - 64px)' }} bgcolor="#fdfdfdeb">
                        <Box display="flex" style={{ height: '100%', width: '100%' }} justifyContent="center">
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    <Box mb={window.innerWidth >= 525 ? 2 : 1} color="#3f51b5" >
                                        <Typography className={`${typographyClasses.h2}`} align="center">Release Your APIs in a Day!!</Typography>
                                    </Box>
                                    <Box color="#3b3b3b" mb={1}>
                                        <Typography className={`${typographyClasses.h3} ${typographyClasses.bold} ${'text-design'}`} align="center">Using Archeplay Low-Code API Platform</Typography>
                                    </Box>
                                    <Box style={{ width: '100%' }} m="auto">
                                        <Grid container alignItems="center" justify="center">
                                            <Grid xs={12} item>
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <Box>
                                                        <WordSwap />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box style={{ width: '100%' }} m="auto">
                                        <Grid container alignItems="center" justify="center">
                                            <Grid xs={12} md={10} xl={8} item>
                                                <Box>
                                                    <Box mt={window.innerWidth >= 525 ? 2 : 0} display="flex" alignItems="center" justifyContent="center" mb={3} color="#3b3b3b">
                                                        <Typography className={typographyClasses.h4}>Design, Develop, Test and Deploy APIs at <span className='text-design' style={{ fontWeight: 'bold' }}>10x</span> speed</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                        <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center">
                                            <Box width={window.innerWidth <= 600 ? 300 : 500} bgcolor="white" mr={2} display="flex" mb={1}>
                                                <input placeholder="example@example.com" className={`${typographyClasses.h6} ${ipClassess.PseudoIp}`} type="email" value={email} onChange={emailOnchange} />
                                            </Box>
                                            <TryNow isHeroAction={true} />
                                        </Box>
                                        <Box>
                                            <BookADemo />
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12} md={5}>
                                    <Box className="hero-bg">

                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Box>
                    </Box>
                    <Box mb={5} style={{ padding: window.innerWidth > 768 ? '1.5rem' : '0', maxWidth: '110rem', margin: 'auto', width: '100%' }} bgcolor="white" id="demo-video-section">
                        <Box mb={3} position="relative">
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h3} ${'text-design'} ${typographyClasses.uppercase}`} align="center">View Archeplay Low-Code API Platform in action</Typography>
                            </Box>
                            <Box style={{ width: window.innerWidth > 768 ? '85%' : '90%', borderColor: 'black' }} borderRadius={10} m="auto" border={window.innerWidth > 768 ? 30 : 15} boxShadow={10}>
                                <img src={AppImage} style={{ width: '100%' }} alt="Archeplay Low Code Platform" />
                            </Box>
                            <Box style={{ width: window.innerWidth > 768 ? '95%' : '97%', borderColor: '#4b4b4b' }} bgcolor="#4b4b4b" borderRadius={10} border={window.innerWidth > 768 ? 15 : 5} boxShadow={10} m="auto">
                            </Box>
                            <Box position="absolute" bgcolor="white" display="flex" alignItems="center" style={{ top: '50%', left: '50%', cursor: 'pointer', borderRadius: '50%' }} boxShadow={5} className="play-button" onClick={() => setDialog(true)}>
                                <PlayCircleFilledIcon className={`${typographyClasses.h1}`} color="primary" />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <TryNow isHeroAction={false} />
                            <BookADemo />
                        </Box>
                    </Box>
                    <Box mb={5} style={{ maxWidth: '110rem', margin: 'auto', width: '100%' }} bgcolor="white">
                        <Box mb={1}>
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h3} ${'text-design'} ${typographyClasses.uppercase}`} align="center">Capabilities of the Low-Code API Platform</Typography>
                            </Box>
                            <Box>
                                <Grid container alignItems="center" justify="center">
                                    <Grid item xs={12} md={10} style={{ margin: 'auto' }}>
                                        <Timeline />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={5} style={{ padding: window.innerWidth > 768 ? '1.5rem' : '0', maxWidth: '110rem', margin: 'auto', width: '100%' }} bgcolor="white">
                        <Box mb={1}>
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h3} ${'text-design'} ${typographyClasses.uppercase}`} align="center">Archeplay Low-Code Platform can be deployed in </Typography>
                            </Box>
                            <Box>
                                <Grid container justify="center">
                                    <Grid item xs={12} md={2} style={{ padding: '5px' }}>
                                        <Box p={2} bgcolor="#3f51b5" style={{ height: '100%' }} display="flex" alignItems="center" justifyContent="center">
                                            <Box>
                                                <Typography className={`${typographyClasses.bold} ${typographyClasses.smallText} ${typographyClasses.root}`}>AWS EC2 VM</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: '5px' }}>
                                        <Box p={2} bgcolor="#3f51b5" style={{ height: '100%', opacity: '0.7' }} display="flex" alignItems="center" justifyContent="center">
                                            <Box>
                                                <Typography className={`${typographyClasses.bold} ${typographyClasses.smallText} ${typographyClasses.root}`}>Windows Laptop- Virtualbox VM - (Coming Soon)</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: '5px' }}>
                                        <Box p={2} bgcolor="#3f51b5" style={{ height: '100%', opacity: '0.7' }} display="flex" alignItems="center" justifyContent="center">
                                            <Box>
                                                <Typography className={`${typographyClasses.bold} ${typographyClasses.smallText} ${typographyClasses.root}`}>MAC Laptop - Virtulabox VM - (Coming Soon )</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: '5px' }}>
                                        <Box p={2} bgcolor="#3f51b5" style={{ height: '100%', opacity: '0.7' }} display="flex" alignItems="center" justifyContent="center">
                                            <Box>
                                                <Typography className={`${typographyClasses.bold} ${typographyClasses.smallText} ${typographyClasses.root}`}>GCP Instance  (Coming Soon )</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: '5px' }}>
                                        <Box p={2} bgcolor="#3f51b5" style={{ height: '100%', opacity: '0.7' }} display="flex" alignItems="center" justifyContent="center">
                                            <Box>
                                                <Typography className={`${typographyClasses.bold} ${typographyClasses.smallText} ${typographyClasses.root}`}>AZURE VM ( Coming Soon )</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={5} style={{ padding: window.innerWidth > 768 ? '1.5rem' : '0', maxWidth: '110rem', margin: 'auto', width: '100%' }} bgcolor="white">
                        <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" flexDirection="column">
                            <Box display="flex">
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h3} ${'text-design'}`}>Want to buy our Product ?</Typography>
                            </Box>
                            <BookADemo />
                        </Box>
                    </Box>
                    {/* <Box style={{ padding: '1.5rem', maxWidth: '110rem', margin: 'auto', width: '100%' }} bgcolor="white" id="plans-block">
                        <Box justifyContent="center" alignItems="center" style={{ width: '100%' }}>
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h3} ${'text-design'} ${typographyClasses.uppercase}`} align="center">Plans</Typography>
                            </Box>
                            <Box>
                                <Grid container justify="center">
                                    {
                                        plans.map((plan, index) => {
                                            return (
                                                <Grid item xs={12} md={4} lg={3} key={index}>
                                                    <Box boxShadow={3} position="relative" style={{ height: '100%' }} bgcolor="#fafafa">
                                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
                                                            <Box display="flex" mb={1} color="#545454">
                                                                <Typography className={`${typographyClasses.h5} ${typographyClasses.bold}`}>{plan.PlanName}</Typography>
                                                            </Box>
                                                            <Box display="flex">
                                                                <Typography>
                                                                    <span className={`${typographyClasses.h5} ${typographyClasses.bold}`}>
                                                                        $&nbsp;{plan['Payment_Definitions'][0]['amount']['value']}
                                                                    </span>
                                                                    <span style={{color:'gray'}}>&nbsp;/&nbsp;Month</span>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            
                                                        </Box>
                                                        <Box p={2} mb={8}>
                                                            <Typography className={typographyClasses.text} align="justify">{plan.PlanDescription}</Typography>
                                                        </Box>
                                                        <Box position="absolute" bottom={0} p={2} style={{ width: '100%' }}>
                                                            <Button
                                                                variant="contained"
                                                                fullWidth
                                                                color="primary"
                                                                className="api-btn"
                                                                onClick={() => checkIsLoggedinForSubscription(plan.PlanId)}
                                                            >
                                                                Subscribe
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </Box> */}
                </Box>
            </Container>
            <Footer page="index" />
            <CookieConsent />
            <Dialog open={dialog} onClose={() => setDialog(false)} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth>
                <DialogTitle id="form-dialog-title">Archeplay Demo Video</DialogTitle>
                <DialogContent>
                    <Box>
                        <iframe style={{ height: window.innerWidth > 768 ? '75vh' : '500px' }} src="https://www.youtube.com/embed/x172xWe-VrU" title="Archeplay Demo Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialog(false)} color="secondary" variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Box style={{ width: '60%' }} ml="auto">
                {
                    docModal === true ?
                        <DocsModal title="Demo Video" content={docsContent} clickHandler={handleDocsModal} innerhtml={false} />
                        :
                        null
                }
            </Box>
        </div>
    )
}

export default Index;