import React, { useState } from 'react';

// Assets
import APLogo from '../../assets/images/ap-logo.png';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

// Router
import { NavLink } from 'react-router-dom';

// Custom Components
import Cognitofunctions from './CognitoFunctions';
import Loader from '../../Components/Loader';
import Alert from '../../Components/Alert';
import Pinpoint from '../../Components/AWSPinpoint';
// import GoogleScript from '../Cognito/LoadGoogleAPIScript';
// import GoogleSignin from './GoogleSign';
import UserRoles from '../../Utils/UserRoles';
import CognitoDetails from './CognitoDetails';

// Google analytics
import ReactGA from 'react-ga';

// React Helmet
import Helmet from "react-helmet/es/Helmet";


const imgStyles = makeStyles((theme) => ({
    logo: {
        height: '32px'
    }
}))

const containerStyles = makeStyles((theme) => ({
    root: {
        padding: '2rem',
        backgroundColor: 'white',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

const boxStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100rem',
        width: '50%',
        [theme.breakpoints.down("sm")]: {
            width: '75%'
        },
        [theme.breakpoints.down("xs")]: {
            width: '95%'
        }
    },
    wrapper: {
        margin: 'auto',
        padding: '1rem 2rem',
    }
}))

const formStyles = makeStyles((theme) => ({
    form: {
        marginTop: '2rem',
        width: '100%'
    },
    input: {
        padding: '5px',
        height: '2rem'
    },
    inputError: {
        padding: '5px',
        height: '2rem',
    },
    label: {
        textAlign: 'left',
        margin: '0 0 10px 0 !important',
    },
    common: {
        width: '100%',
        margin: '10px 0',
        borderRadius: 0
    }
}));

const commonStyles = makeStyles((theme) => ({
    wrapper: {
        width: 'calc(100% - 64px)',
        padding: '1rem',
        margin: 'auto'
    }
}))

const SignupIndex = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loader, setLoader] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverrity] = useState('')
    const [message, setMessage] = useState('')

    const imgClasses = imgStyles()
    const containerClasses = containerStyles()
    const boxClasses = boxStyles()
    const formClasses = formStyles()
    const commonClasses = commonStyles()

    const handleAlert = () => {
        setOpenAlert(false)
    }

    React.useEffect(() => {
        // if (!window.gapi) {
        //     GoogleScript.CheckGoogleAPI()
        // }
        initializeReactGA()
        if (sessionStorage.getItem('signupemail')) {
            setEmail(sessionStorage.getItem('signupemail'))
        }
    }, [])

    function initializeReactGA() {
        // devlocal
        ReactGA.initialize('UA-174472554-3');
        // prod
        // ReactGA.initialize('UA-174472554-2');
        ReactGA.pageview('/app/signup');
    }

    const SignUp = () => {
        setLoader(true)
        Cognitofunctions.Signup({ 'email': email, 'password': password }, function (success, data) {
            if (success) {
                Pinpoint.TrackEvents({
                    name: 'UserSignedUp',
                    attributes: {
                        EmailId: email
                    }
                })
                setMessage("Account created successfully")
                setSeverrity('success')
                setOpenAlert(true)
                Login()
            }
            else {
                setLoader(false)
                setMessage(data.message)
                setSeverrity('error')
                setOpenAlert(true)
            }
        })
    }

    // const ResendCode = () => {
    //     setLoader(true)
    //     Cognitofunctions.Resendcode({ 'email': email }, function (success, data) {
    //         if (success) {
    //             setLoader(false)
    //             setVerificationCode('')
    //             setMessage("Code resent successfully")
    //             setSeverrity('success')
    //             setOpenAlert(true)
    //         }
    //         else {
    //             setLoader(false)
    //             setMessage(data.message)
    //             setSeverrity('error')
    //             setOpenAlert(true)
    //         }
    //     })
    // }

    const Login = () => {
        setLoader(true)
        Cognitofunctions.Signin({ "email": email, "password": password }, function (success, data) {
            if (success) {
                if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setLoader(false)
                }
                else {
                    getCurrentUser()
                }
            }
            else {
                setLoader(false)
                if (data.code !== 'UserNotConfirmedException') {
                    setMessage(data.message)
                    setSeverrity('error')
                    setOpenAlert(true)
                }
                // if (data.code === "UserNotConfirmedException") {
                //     // setOpenLogin(false)
                //     // setOpenNotValidated(true)
                //     getCurrentUser()
                // }
            }
        })
    }

    const getCurrentUser = () => {
        setLoader(true)
        Cognitofunctions.CurrentUser(function (success, data) {
            if (success) {
                // GetTenants(data.attributes.email);
                GetUser()
            }
            else {
                setLoader(false)
                window.location.href = window.location.origin + "/#/app/login"
            }
        })
    }

    const GetUser = () => {
        UserRoles.GetUser(function (success, result) {
            if (success) {
                if (result.hasOwnProperty('statusCode') && result.statusCode === 200) {
                    CognitoDetails.User = result.body
                    sessionStorage.setItem('user', window.btoa(JSON.stringify(result.body)))
                    window.location.href = window.location.origin + "/#/user"
                    // if (sessionStorage.getItem('subscribe')) {
                    //     window.location.href = window.location.origin + "/#/user/addsubscriptions?planId=" + sessionStorage.getItem('subscribe')
                    // }
                    // else {
                    //     if (result.body.Trial === "NotDone") {
                    //     }
                    //     else {
                    //         window.location.href = window.location.origin + "/#/app/products"
                    //     }
                    // }
                }
                else {
                    if (result.hasOwnProperty('errorMessage')) {
                        setMessage(result.errorMessage)
                    }
                    else {
                        setMessage("Some internal error.Please try after some time.")
                    }
                    setSeverrity('error')
                    setOpenAlert(true)
                    setLoader(false)
                }
            }
            else {
                if (result.hasOwnProperty('errorMessage')) {
                    setMessage(result.errorMessage)
                }
                else {
                    setMessage("Some internal error.Please try after some time.")
                }
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
            }
        })
    }

    const GetTenants = (EmailId) => {
        UserRoles.GetUserTenants(EmailId, function (success, result) {
            if (success) {
                if (result.hasOwnProperty('statusCode') && result.statusCode === 200) {
                    CognitoDetails.TenantsList = result.body.Tenants
                    if (result.body.Tenants.length > 0 && result.body.Tenants.length === 1) {
                        sessionStorage.setItem('Tenants', window.btoa(JSON.stringify(result.body.Tenants)))
                        sessionStorage.setItem('TenantId', result.body.Tenants[0].TenantId)
                        GetUser();
                    }
                    else if (result.body.Tenants.length > 0) {
                        sessionStorage.setItem('Tenants', window.btoa(JSON.stringify(result.body.Tenants)))
                        GetUser();
                    }
                    else {
                        GetUser();
                    }
                }
                else {
                    if (result.hasOwnProperty('errorMessage')) {
                        setMessage(result.errorMessage)
                    }
                    else {
                        setMessage("Some internal error.Please try after some time.")
                    }
                    setSeverrity('error')
                    setOpenAlert(true)
                    setLoader(false)
                }
            }
            else {
                if (result.hasOwnProperty('errorMessage')) {
                    setMessage(result.errorMessage)
                }
                else {
                    setMessage("Some internal error.Please try after some time.")
                }
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
            }
        })
    }

    // const ConfirmSignUp = () => {
    //     setLoader(true)
    //     Cognitofunctions.Confirmsignup({ 'email': email, 'code': verificationCode }, function (success, data) {
    //         if (success) {
    //             ReactGA.event({
    //                 category: "Register",
    //                 action: 'Sign Up'
    //             })
    //             Pinpoint.TrackEvents({
    //                 name: 'UserSignedUp',
    //                 attributes: JSON.parse(sessionStorage.getItem('user_attrs'))
    //             })
    //             setMessage("Account verified successfully.")
    //             setSeverrity('success')
    //             setOpenAlert(true)
    //             setTimeout(function () {
    //                 // window.location.href = window.location.origin + "/login"
    //                 // window.location.origin = window.location.origin
    //                 history.push('/app/login')
    //             }, 1000)
    //         }
    //         else {
    //             setLoader(false)
    //             setMessage(data.message)
    //             setSeverrity('error')
    //             setOpenAlert(true)
    //         }
    //     })
    // }

    // const validateEmail = (value) => {
    //     let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    //     if (reg.test(value) === false) {
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }
    // }

    // const validatePassword = (value) => {

    // }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    // const handleNameChange = (event) => {
    //     setUserName(event.target.value)
    // }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    // const handleVerficationCodeChange = (event) => {
    //     setVerificationCode(event.target.value)
    // }

    const handleEnterClick = (clickHandler, event) => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault()
            clickHandler()
        }
    }

    return (
        <div>
            <Loader open={loader} type="page" />
            <Helmet>
                <title>Signup - Register to Archeplay</title>
            </Helmet>
            <Alert openAlert={openAlert} severity={severity} message={message} handler={handleAlert} />
            <Container className={containerClasses.root}>
                <Box className={boxClasses.root} boxShadow={2} bgcolor="#fcfcfc">
                    <Box className={boxClasses.wrapper}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex">
                                <img className={imgClasses.logo} src={APLogo} alt="Archeplay" />
                            </Box>
                            <Box display="flex">
                                <h2 style={{ margin: '7px' }}>Register</h2>
                            </Box>
                        </Box>
                        <Box>
                            <div className={commonClasses.wrapper}>
                                <form className={formClasses.form} autoComplete="off">
                                    <FormControl className={formClasses.common}>
                                        <label required className={`${formClasses.label} ${formClasses.common}`}>Email Address</label>
                                        <input type="email" placeholder="example@domain.com" onChange={handleEmailChange} value={email}></input>
                                    </FormControl>
                                    {/* <FormControl className={formClasses.common}>
                                        <label required className={`${formClasses.label} ${formClasses.common}`}>Name</label>
                                        <input type="email" placeholder="Enter Name" onChange={handleNameChange}></input>
                                    </FormControl> */}
                                    <FormControl className={formClasses.common}>
                                        <label required className={`${formClasses.label} ${formClasses.common}`}>Password</label>
                                        <input type="password" placeholder="********" onChange={handlePasswordChange} onKeyPress={handleEnterClick.bind(this, SignUp)}></input>
                                        <small style={{ color: 'darkgrey', textAlign: 'justify' }}>Your password should be more than seven characters and it should contain
                                        atleast one uppercase,one lowercase,one special
                                        character and one numeric value.</small>
                                    </FormControl>
                                    {/* {
                                        openVerficationCode === true ?
                                            <Box>
                                                <FormControl className={formClasses.common}>
                                                    <label className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-pass">Verification Code</label>
                                                    <input type="text" placeholder="Enter 6 digit verification code" onChange={handleVerficationCodeChange} onKeyPress={handleEnterClick.bind(this, ConfirmSignUp)}></input>
                                                </FormControl>
                                                <Button
                                                    className={formClasses.common}
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={ResendCode}
                                                >
                                                    Resend code
                                            </Button>
                                            </Box>
                                            :
                                            null
                                    } */}
                                    <Button
                                        className={formClasses.common}
                                        color="primary"
                                        variant="contained"
                                        onClick={SignUp}
                                    // onClick={openVerficationCode === true ? ConfirmSignUp : SignUp}
                                    >
                                        Sign up
                                </Button>
                                    {/* <Box mt={1} mb={2} display="flex" alignItems="center" justifyContent="center">
                                    or
                                </Box>
                                <GoogleSignin /> */}
                                </form>
                                <Box display="flex" justifyContent="space-around" alignItems="center" mt={2}>
                                    <NavLink to="/app/login" style={{ textDecoration: 'none' }}>
                                        Have an account? click here
                                </NavLink>
                                </Box>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default SignupIndex