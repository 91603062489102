import React, { useState } from 'react';

// Material UI
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// Assets
import ApLogo from '../assets/images/ap-logo.png';

// Material Icons
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import ClearIcon from '@material-ui/icons/Clear';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Typography from '@material-ui/core/Typography';

// Router
import { NavLink } from 'react-router-dom';

// Styles
import TypographyStyles from '../assets/styles/TypographySyles';


// const drawerWidth = 240;

const fixedDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: '1px solid #0000001f',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        color: '#0000008a',
        '&:hover': {
            backgroundColor: 'transparent',
            color: 'black'
        }
    },
    hide: {
        display: 'none',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '120px',
        [theme.breakpoints.down('up')]: {
            display: 'none',
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    List: {
        marginTop: 64,
        padding: 0
    },
    ListItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '0',
        '&:hover': {
            backgroundImage: 'linear-gradient(45deg, #2196F3, #3F51B5)',
            color: 'white'
        }
    },
    ListIcon: {
        color: "inherit",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        lineHeight: '1.2',
        minWidth: 'auto',
        flexDirection: 'column',
        '&:hover': {
            color: 'white'
        }
    },
    APLogo: {
        height: '33px',
        cursor: 'pointer'
    },
    candlLogo: {
        cursor: 'pointer',
        height: '50px'
    }
}));

const hiddenDrawerStyles = makeStyles({
    list: {
        width: 350,
        padding: 0
    },
    fullList: {
        width: 'auto',
    },
    ListItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        padding: '0 1rem',
        '&:hover': {
            backgroundColor: 'whitesmoke',
        }
    },
    ListIcon: {
        color: "inherit",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlign: 'center',
        whiteSpace: 'normal',
        lineHeight: '1',
        minWidth: 'auto',
        '&:hover': {
            color: 'black',
        }
    },
    ListText: {
        flex: '0 1 auto'
    }
});

const navStyles = makeStyles({
    links: {
        'cursor': 'pointer',
        "color": '#3F51B5',
        '&:hover': {
            color: 'black'
        }
    }
})

function MiniDrawer({ page }) {
    const fixedDrawerClasses = fixedDrawerStyles();
    const hiddenDrawerClasses = hiddenDrawerStyles();
    const typographyClasses = TypographyStyles()
    const navClasses = navStyles()

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(hiddenDrawerClasses.list, {
                [hiddenDrawerClasses.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <nav>
                <Box padding={2} height={75} style={{ borderBottom: '1px solid lightgrey' }} display="flex" alignItems="center" justifyContent="space-between">
                    <Box onClick={() => {
                        window.location.href = window.location.origin
                    }}>
                        <img className={fixedDrawerClasses.APLogo} src={ApLogo} alt="Archeplay" />
                    </Box>
                    <Box>
                        <IconButton
                            onClick={() => toggleDrawer("top", false)}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                </Box>
                {
                    page === "automation" ?
                        <List className={hiddenDrawerClasses.List}>
                            <ListItem button key="Home" className={hiddenDrawerClasses.ListItem}>
                                <NavLink className="main-anchor" to="/" style={{ textDecoration: 'none', width: '100%' }} activeStyle={{ color: 'blue' }}>
                                    <ListItemIcon className={hiddenDrawerClasses.ListIcon}>
                                        <ListItemText primary={"Home"} className={hiddenDrawerClasses.ListText} />
                                    </ListItemIcon>
                                </NavLink>
                            </ListItem>
                            {/* <ListItem button key="Users" className={hiddenDrawerClasses.ListItem}>
                                <ListItemIcon className={hiddenDrawerClasses.ListIcon}>
                                    <GroupIcon style={{ marginRight: 7 }} />
                                    <ListItemText primary={"Examples"} className={hiddenDrawerClasses.ListText} />
                                </ListItemIcon>
                            </ListItem> */}
                            <ListItem button key="Admin" className={hiddenDrawerClasses.ListItem} onClick={() => handleNavclick('tools')}>
                                <ListItemIcon className={hiddenDrawerClasses.ListIcon}>
                                    <SettingsIcon style={{ marginRight: 7 }} />
                                    <ListItemText primary={"Tools"} className={hiddenDrawerClasses.ListText} />
                                </ListItemIcon>
                            </ListItem>
                            <ListItem button key="Learn" className={hiddenDrawerClasses.ListItem} onClick={() => handleNavclick('Contact')}>
                                <ListItemIcon className={hiddenDrawerClasses.ListIcon}>
                                    <MenuBookIcon style={{ marginRight: 7 }} />
                                    <ListItemText primary={"Talk to us"} className={hiddenDrawerClasses.ListText} />
                                </ListItemIcon>
                            </ListItem>
                        </List>
                        :
                        null
                }
                {
                    page === "index" ?
                        <List className={hiddenDrawerClasses.List}>
                            {/* <ListItem button key="Docs" className={hiddenDrawerClasses.ListItem}>
                                <NavLink className="main-anchor" to="/app/login" style={{ textDecoration: 'none', width: '100%' }} activeStyle={{ color: 'blue' }}>
                                    <ListItemIcon className={hiddenDrawerClasses.ListIcon}>
                                        <ListItemText primary={"Docs"} className={hiddenDrawerClasses.ListText} />
                                    </ListItemIcon>
                                </NavLink>
                            </ListItem> */}
                            <ListItem button key="Demo" className={hiddenDrawerClasses.ListItem}>
                                <NavLink className="main-anchor" to="/contact-us" style={{ textDecoration: 'none', width: '100%' }} activeStyle={{ color: 'blue' }}>
                                    <ListItemIcon className={hiddenDrawerClasses.ListIcon}>
                                        <ListItemText primary={"Demo"} className={hiddenDrawerClasses.ListText} />
                                    </ListItemIcon>
                                </NavLink>
                            </ListItem>
                            <ListItem button key="Login" className={hiddenDrawerClasses.ListItem}>
                                <NavLink className="main-anchor" to="/app/login" style={{ textDecoration: 'none', width: '100%' }} activeStyle={{ color: 'blue' }}>
                                    <ListItemIcon className={hiddenDrawerClasses.ListIcon}>
                                        <ListItemText primary={"Login"} className={hiddenDrawerClasses.ListText} />
                                    </ListItemIcon>
                                </NavLink>
                            </ListItem>
                        </List>
                        :
                        null
                }
            </nav>
        </div >
    );

    return (
        <div className={fixedDrawerClasses.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={fixedDrawerClasses.appBar}
            >
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%', maxWidth: page === "app" ? '100%' : '110rem', margin: 'auto' }}>
                        <Box display="flex" alignItems="center" onClick={() => {
                            window.location.href = window.location.origin
                        }}>
                            <img className={fixedDrawerClasses.APLogo} src={ApLogo} alt="Archeplay" />
                        </Box>
                        {
                            window.innerWidth >= 768 ?
                                <Box display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        {/* <Box mr={6}>
                                            <Box display="flex" className={navClasses.links}>
                                                <Typography color="inherit" className={typographyClasses.text}>Docs</Typography>
                                            </Box>
                                        </Box> */}
                                        <Box mr={6}>
                                            <Box display="flex" className={navClasses.links} onClick={() => {
                                                window.location.href = window.location.origin + "/#/contact-us"
                                            }}>
                                                <Typography color="inherit" className={typographyClasses.text}>Demo</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box display="flex" p={1} pl={3} pr={3} style={{ backgroundImage: "linear-gradient(45deg, #2196F3, #3F51B5)",cursor:'pointer' }} onClick={() => {
                                                window.location.href = window.location.origin+"/#/app/login"
                                            }}>
                                                <Typography className={`${typographyClasses.root} ${typographyClasses.text}`}>Login</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                :
                                <Box display="flex" alignItems="center">
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={toggleDrawer("top", true)}
                                        edge="start"
                                        className={fixedDrawerClasses.menuButton}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Box>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            <React.Fragment key={"top"}>
                <Drawer anchor={"top"} open={state["top"]} onClose={toggleDrawer("top", false)}>
                    {list("top")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}


const handleNavclick = (key) => {
    if (key === "Environments") {
        let elmnt = document.getElementById("env-blk");
        elmnt.scrollIntoView();
    }
    else if (key === "Contact") {
        let elmnt = document.getElementById("con-us");
        elmnt.scrollIntoView();
    }
    else if (key === "tools") {
        let elmnt = document.getElementById("tools");
        elmnt.scrollIntoView();
    }
}

export default { handleNavclick, MiniDrawer };