import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Styles
import TypographyStyles from '../../assets/styles/TypographySyles';

const FooterLayout = ({ ...props }) => {
    const typographyClasses = TypographyStyles()

    const { heading, content } = props

    return (
        <Box mb={1}>
            <Box color="#2196F3" mb={1} display="flex">
                <Typography className={`${typographyClasses.bold} ${typographyClasses.h6}`}>{heading}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                {
                    Object.keys(content).map((ct, index) => {
                        return (
                            <Box display="flex" flexDirection="column" style={{ fontSize: '0.9rem' }} p={0.5} pl={0} pr={0} key={heading+index}>
                                {
                                    content[ct].hasOwnProperty('extlink') === true ?
                                        <Typography className={`${typographyClasses.root}`}>
                                            {ct}&nbsp;&nbsp;-&nbsp;&nbsp;
                                            <a href={content[ct]['extlink']} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>{content[ct]['value']}</a>
                                        </Typography>
                                        :
                                        <Typography className={`${typographyClasses.bold} ${typographyClasses.h6}`}>{content[ct]['value']}</Typography>
                                }
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default FooterLayout;