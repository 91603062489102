import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import '../Utils/AmplifyData';
import Login from './Cognito/Index';
import ForgotPassword from './Cognito/ForgotPassword';
import Signup from './Cognito/SignUp';
import Index from './Frontpage/Index';
import ContactUs from './Frontpage/contact-us';
import PrivacyPolicy from './Frontpage/Privacy-Policy';
// import ProductList from './Product/ProductIndex'
// import ServicesList from './Services/ServicesList';
// import ServiceDetails from './Services/ServiceDetails';
import User from './User/index';
// import SubscribePlan from './User/Ecommerce/subscribe';
// import Subscriptions from './User/Ecommerce/subscriptions';

class AppRoute extends Component {
	render() {
		return (
			<div>
				<HashRouter>
					<Switch>
						<Route exact path="/app/login" component={Login} />
						<Route exact path="/app/login/forgot-password" component={ForgotPassword} />
						<Route exact path="/app/signup" component={Signup} />
						<Route exact path="/privacy-policy" component={PrivacyPolicy} />
						<Route exact path="/contact-us" component={ContactUs} />
						 <Route exact path="/" component={Index} />
						{/* <Route exact path="/app/products" component={ProductList}/> */}
						{/* <Route exact path="/app/products/:productid/services/:serviceid" component={ServiceDetails} /> */}
						{/* <Route exact path="/app/products/:productid/services" component={ServicesList} /> */}
						{/* <Route exact path="/user/addsubscriptions" component={SubscribePlan} /> */}
						{/* <Route exact path="/user/subscriptions" component={Subscriptions} /> */}
						<Route exact path="/user" component={User} />
					</Switch>
				</HashRouter>
			</div>
		);
	}
}

export default AppRoute;