import React, { useState } from 'react';

//Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


const Confirm = ({ confirmText, clickHandler }) => {
    const [text, setText] = useState('no')

    const handleText = (event) => {
        setText(event.target.value)
    }

    return (
        <Dialog
            open={true}
            onClose={() => clickHandler(false)}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <label>{confirmText}</label>
                    <label >Please enter "yes" to confirm</label>
                    <TextField
                        id="ClassName"
                        required
                        fullWidth
                        placeholder="yes"
                        onChange={handleText}
                        variant="outlined"
                    >
                    </TextField>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => clickHandler(false)} color="primary" className="action-btn">
                    Cancel
                </Button>
                <Button disabled={text === 'yes' ? false : true} variant="contained" onClick={() => clickHandler(true)} className="api-btn">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Confirm; 