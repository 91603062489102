import React, { useState, useEffect } from 'react';

// Material UI
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

// Custom Components
import Alert from '../../Components/Alert';
import Header from '../../Components/TopNav';
import Loader from '../../Components/Loader';
import Footer from '../../Components/Footer/index';
import apidomain from '../../Utils/ApiDomain';
import CookieConsent from './Cookie-Consent';
import Pinpoint from '../../Components/AWSPinpoint';
import CognitoFunctions from '../Cognito/CognitoFunctions';

// Styles
import TypographyStyles from '../../assets/styles/TypographySyles';

// Material Icons
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';

// Google Recaptcha
import { load } from 'recaptcha-v3'

// React Helmet
import Helmet from "react-helmet/es/Helmet";

//Phone number module
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const containerStyles = makeStyles((theme) => ({
    root: {
        padding: '0',
        maxWidth: '110rem',
        display: 'flex',
        marginTop: '64px',
        width: '100%'
    }
}))


const Index = () => {
    const [loader, setLoader] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverrity] = useState('')
    const [message, setMessage] = useState('')
    const [formData, setFormData] = useState({})
    const [phone, setPhone] = useState('')
    const [showCal, setShowCal] = useState(false)
    const [freeTrial, setFreeTrial] = useState("No")
    const [password, setPassword] = useState("")
    const [showThanks, setShowThanks] = useState(false)

    const FooterData = [
        {
            "heading": "Contact",
            "content": {
                "Email": {
                    "extlink": "mailto:talktous@archeplay.com",
                    "value": "talktous@archeplay.com"
                },
                "Call": {
                    "extlink": "tel:+17326031380",
                    "value": "+17326031380"
                },
                "WhatsApp": {
                    "extlink": "https://api.whatsapp.com/send?phone=919606381617",
                    "value": "+919606381617"
                }
            }
        }
    ]

    const booleanMap = {
        "No": false,
        "Yes": true
    }

    const containerClasses = containerStyles()
    const typographyClasses = TypographyStyles()
    // const imageClassess = imageStyles()
    // const tabClasses = tabStyles();

    const pinpointId = 'aws-amplify-cacheAWSPinpoint_5c6a00888b7e476e8c5212363def7fcf'
    // const pinpointId = 'aws-amplify-cacheAWSPinpoint_db82db1fa74f42a78f9de7a998b25103'


    // let history = useHistory();

    useEffect(() => {
        let attrs = {}
        if (!sessionStorage.getItem('user_attrs')) {
            let getpinpoint = window.setInterval(function () {
                if (localStorage.getItem(pinpointId)) {
                    attrs['uuid'] = JSON.parse(localStorage.getItem(pinpointId))['data']
                    sessionStorage.setItem('user_attrs', JSON.stringify(attrs))
                    clearInterval(getpinpoint)
                }
            }, 1000)
            if (window.location.href.indexOf('?') !== -1) {
                let url = window.location.href.split('?')
                let params = ''
                if (window.location.href.indexOf('&') !== -1) {
                    params = url[1].split('&')
                }
                else {
                    params = [url[1]]
                }
                for (let i = 0; i < params.length; i++) {
                    if (params[i].indexOf('UserId') !== -1) {
                        attrs['UserId'] = params[i].split('=')[1]
                        sessionStorage.setItem('user_attrs', JSON.stringify(attrs))
                    }
                    else if (params[i].indexOf('CampaignId') !== -1) {
                        attrs['CampaignId'] = params[i].split('=')[1]
                        sessionStorage.setItem('user_attrs', JSON.stringify(attrs))
                    }
                }
            }
            else {
                sessionStorage.setItem('user_attrs', JSON.stringify(attrs))
            }
        }
        else {
            attrs = JSON.parse(sessionStorage.getItem('user_attrs'))
        }
        window.addEventListener(
            'message',
            function (e) {
                if (isCalendlyEvent(e)) {
                    if (e.data.event === "calendly.event_scheduled") {
                        sessionStorage.setItem('DemoBooked', 'yes')
                    }
                }
            }
        );
        if (sessionStorage.getItem('DemoBooked')) {
            setLoader(true)
            let params = new URL(window.location.href)
            manageAPIs({
                APIhref: "/contact/contactus",
                body: {
                    Action: "Update_Contact",
                    Contact_Id: sessionStorage.getItem('conId'),
                    EmailId: sessionStorage.getItem('EmailId'),
                    start: new Date(params.searchParams.get('event_start_time')).toUTCString(),
                    end: new Date(params.searchParams.get('event_end_time')).toUTCString()
                }
            })
        }
    }, [])

    function isCalendlyEvent(e) {
        return e.data.event &&
            e.data.event.indexOf('calendly') === 0;
    };

    const manageAPIs = (apidata) => {
        let apidom = apidomain.url
        if (apidom === undefined || apidom === "") {
            apidom = sessionStorage.getItem('apidomain')
        }
        return fetch(apidom + apidata.APIhref, { method: "POST", body: JSON.stringify(apidata.body) })
            .then(result => result.json())
            .then(result => {
                if (result.status === 200) {
                    if (apidata.APIhref === "/contact/recaptcha") {
                        let data = JSON.parse(result.body.response)
                        if (data['success'] && data['score'] > 0.5 && data['action'] === "contactus" && (data['hostname'].indexOf('localhost') !== -1 || data['hostname'].indexOf('archeplay.com') !== -1)) {
                            CheckIsFreeTrialEnabled()
                        }
                        else {
                            setMessage("You are not a proper user to do this operation");
                            setSeverrity('error');
                            setOpenAlert(true);
                            setLoader(false);
                        }
                    }
                    else if (apidata.APIhref === "/contact/contactus") {
                        if (apidata.body.Action === "AddNewContact") {
                            let calData = apidata.body.contactDetails
                            Pinpoint.TrackEvents({
                                name: 'UserDetailsCapturedForDemoBooking',
                                attributes: calData
                            })
                            // let calData = { ...JSON.parse(sessionStorage.getItem('user_attrs')), ...apidata.body.contactDetails }
                            sessionStorage.setItem('conId', result.body.Contact_Id)
                            sessionStorage.setItem('EmailId', calData.EmailId)
                            setShowCal(true)
                            window.Calendly.initInlineWidget({
                                url: 'https://calendly.com/mohanraj-shanmugam/30min',
                                // url: 'https://calendly.com/swami7798/15min',
                                parentElement: document.getElementById('calendly-embed'),
                                prefill: {
                                    name: calData.Company_Name,
                                    email: calData.EmailId,
                                    customAnswers: calData
                                },
                            });
                            setLoader(false)
                            sessionStorage.setItem('FreeTrialEnabled', freeTrial)
                        }
                        else if (apidata.body.Action === "Update_Contact") {
                            setLoader(false)
                            Pinpoint.TrackEvents({
                                name: 'DemoBooked',
                                attributes: {
                                    EmailId: sessionStorage.getItem('EmailId'),
                                }
                            })
                            sessionStorage.removeItem('DemoBooked')
                            sessionStorage.removeItem('EmailId')
                            sessionStorage.removeItem('conId')
                            setShowThanks(true)
                            if (sessionStorage.getItem('FreeTrialEnabled') === "Yes") {
                                sessionStorage.removeItem('FreeTrialEnabled')
                                setFreeTrial('Yes')
                                window.setTimeout(function(){
                                    window.location.href = window.location.origin+"/#/app/login"
                                },3000)
                            }
                        }
                    }
                }
                else {
                    setMessage(result.errorMessage);
                    setSeverrity('error');
                    setOpenAlert(true);
                    setLoader(false);
                }
            })
            .catch(err => {
                setMessage("Some internal error please try after some time.");
                setSeverrity('error');
                setOpenAlert(true);
                setLoader(false);
            })
    }

    const handleActions = (actionData) => {
        if (!actionData.hasOwnProperty('Action')) {
            setLoader(true)
            manageAPIs(actionData)
        }
        else if (actionData.Action === "redirect") {
            window.open(actionData.Url, '_blank')
        }
    }

    const handleAlert = () => {
        setOpenAlert(false)
    }

    const handleFormDataChage = (field, event) => {
        let formFields = formData;
        if (field === "EnableFreeTrial") {
            formFields[field] = booleanMap[event.target.value]
            setFreeTrial(event.target.value)
        }
        else if (field === "NoOfAPIs" || field === "NoOfDevelopers/Frelancers") {
            if (event.target.value < 0) {
                formFields[field] = parseInt("0")
                event.target.value = 0
            }
            else {
                formFields[field] = parseInt(event.target.value)
            }
        }
        else {
            formFields[field] = event.target.value;
        }
        setFormData(formFields);
    }

    const handlePhoneNumber = (phone) => {
        setPhone(phone)
    }

    const triggerRecaptcha = () => {
        setLoader(true)
        load('6LehBskUAAAAAGm9wwr_hNuuRXXyyZVppXlz2vaF').then((recaptcha) => {
            recaptcha.execute('contactus').then((token) => {
                handleActions({
                    APIhref: "/contact/recaptcha",
                    body: {
                        response: token
                    }
                })
            })
        })
    }

    const ContactFormSubmit = () => {
        let canSubmit = true
        let formFields = formData;
        formFields['MobileNumber'] = phone;
        if (phone.length >= 11) {
            if (phone[0] === "9" && phone.length !== 12) {
                setLoader(false)
                canSubmit = false
                setMessage('Please Enter a valid Mobile Number')
                setSeverrity('error')
                setOpenAlert(true)
            }
            else if (phone[0] === "1" && phone.length !== 11) {
                setLoader(false)
                canSubmit = false
                setMessage('Please Enter a valid Mobile Number')
                setSeverrity('error')
                setOpenAlert(true)
            }
        }
        else {
            delete formFields['MobileNumber']
        }
        formFields['EnableFreeTrial'] = freeTrial
        let reqAttr = ['EmailId', "EnableFreeTrial", 'Company_Name']
        for (var i = 0; i < reqAttr.length; i++) {
            if (!formFields.hasOwnProperty(reqAttr[i]) || formFields[reqAttr[i]] === '') {
                canSubmit = false
                setMessage("Please fill the required details.")
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
                return;
            }
        }
        if (canSubmit) {
            formFields = { ...formFields, ...JSON.parse(sessionStorage.getItem('user_attrs')) }
            formFields['Time_Stamp'] = new Date().toUTCString()
            let apidata = {
                contactDetails: formFields,
                Action: 'AddNewContact'
            }
            manageAPIs({
                APIhref: "/contact/contactus",
                body: apidata
            })
            console.log(apidata)
        }
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const CheckIsFreeTrialEnabled = () => {
        setLoader(true)
        if (freeTrial === "Yes") {
            let pass = password, canSubmit = true
            let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
            if (!regex.test(pass)) {
                canSubmit = false
                setLoader(false)
                setMessage("Please enter a valid password.")
                setSeverrity('error')
                setOpenAlert(true)
            }
            let formfields = formData
            if (!formfields.hasOwnProperty('EmailId')) {
                canSubmit = false
                setLoader(false)
                setMessage("Please enter a valid EmailId.")
                setSeverrity('error')
                setOpenAlert(true)
            }
            if (canSubmit) {
                CognitoFunctions.Signup({ 'email': formfields['EmailId'], 'password': password }, function (success, data) {
                    if (success) {
                        Pinpoint.TrackEvents({
                            name: 'UserSignedUp',
                            attributes: {
                                EmailId: formfields['EmailId']
                            }
                        })
                        ContactFormSubmit()
                    }
                    else {
                        setLoader(false)
                        if (data.message === "An account with the given email already exists.") {
                            setMessage("For the provided Email Id, Trial is already enabled, Please provide another Email Id or select 'No' for Enable Free Trial.")
                        }
                        else {
                            setMessage(data.message)
                        }
                        setSeverrity('error')
                        setOpenAlert(true)
                    }
                })
            }
        }
        else {
            ContactFormSubmit()
        }
    }

    return (
        <div>
            <Loader open={loader} type="page" />
            <Helmet>
                <title>Contact Us - For any queries try to reach out us.</title>
                <meta name="description" content="Contact Us - For any queries try to reach out us." />
            </Helmet>
            <Alert openAlert={openAlert} severity={severity} message={message} handler={handleAlert} />
            <Header.MiniDrawer page="index" />
            <Container className={containerClasses.root}>
                <Box display="flex" flexDirection="column" style={{ width: '100%', minHeight: '90vh' }}>
                    <Box style={{ padding: '1.5rem', maxWidth: '110rem', margin: 'auto', width: '100%', height: '100%' }} bgcolor="#fdfdfdeb">
                        <Box style={{ width: '90%' }} m="auto">
                            <Grid container justify="center" alignItems="center">
                                {
                                    showCal === false && showThanks === false ?
                                        <Grid item xs={12} md={7}>
                                            <Box style={{ width: '100%', height: '100%' }} boxShadow={2} borderRadius={16}>
                                                <Box p={3} bgcolor="#2196F3" style={{ borderRadius: '1rem 1rem 0 0' }}>
                                                    <Typography className={`${typographyClasses.bold} ${typographyClasses.root} ${typographyClasses.h4}`}>Book a Demo</Typography>
                                                </Box>
                                                <Box>
                                                    <form>
                                                        <Grid container justify="space-between">
                                                        <Grid item xs={12} style={{ padding: '0.5rem 1rem 0.5rem 1rem', borderBottom: '1px solid lightgrey' }}>
                                                                <FormControl fullWidth>
                                                                    <label className={typographyClasses.bold} htmlFor="EmailId" required>Email Id</label>
                                                                    <TextField
                                                                        id="EmailId"
                                                                        required
                                                                        fullWidth
                                                                        onChange={handleFormDataChage.bind(this, "EmailId")}
                                                                        variant="outlined"
                                                                        type="email"
                                                                    >
                                                                    </TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ padding: '0.5rem 1rem 0.5rem 1rem', borderBottom: '1px solid lightgrey' }}>
                                                                <FormControl className={typographyClasses.bold} fullWidth>
                                                                    <label htmlFor="Company_Name" required>Company Name</label>
                                                                    <TextField
                                                                        id="Company_Name"
                                                                        required
                                                                        fullWidth
                                                                        onChange={handleFormDataChage.bind(this, "Company_Name")}
                                                                        variant="outlined"
                                                                        type="text"
                                                                    >
                                                                    </TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ padding: '0.5rem 1rem 0.5rem 1rem', borderBottom: '1px solid lightgrey' }}>
                                                                <FormControl fullWidth>
                                                                    <label htmlFor="NoOfAPIs" className={typographyClasses.bold}>Number of APIs used in your product?</label>
                                                                    <TextField
                                                                        id="NoOfAPIs"
                                                                        fullWidth
                                                                        onChange={handleFormDataChage.bind(this, "NoOfAPIs")}
                                                                        variant="outlined"
                                                                        type="number"
                                                                    >
                                                                    </TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ padding: '0.5rem 1rem 0.5rem 1rem', borderBottom: '1px solid lightgrey' }}>
                                                                <FormControl fullWidth>
                                                                    <label htmlFor="NoOfDevelopers/Frelancers" className={typographyClasses.bold}>How many API Developers / Freelancers working on your product?</label>
                                                                    <TextField
                                                                        id="NoOfDevelopers/Frelancers"
                                                                        fullWidth
                                                                        onChange={handleFormDataChage.bind(this, "NoOfDevelopers/Frelancers")}
                                                                        variant="outlined"
                                                                        type="number"
                                                                    >
                                                                    </TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ padding: '0.5rem 1rem 0.5rem 1rem', borderBottom: '1px solid lightgrey' }}>
                                                                <FormControl fullWidth>
                                                                    <label className={typographyClasses.bold} htmlFor="PhoneNo">Phone Number</label>
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        enableAreaCodes={true}
                                                                        enableSearch={true}
                                                                        onChange={phone => handlePhoneNumber(phone)}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ padding: '0.5rem 1rem 0 1rem', borderBottom: '1px solid lightgrey' }}>
                                                                <FormControl fullWidth>
                                                                    <label className={typographyClasses.bold} htmlFor="EnableFreeTrial" required>Enable 14 days Free trial?</label>
                                                                    <RadioGroup onChange={handleFormDataChage.bind(this, 'EnableFreeTrial')} value={freeTrial} >
                                                                        <FormControlLabel style={{ marginBottom: 0 }} value="Yes" control={<Radio />} label="Yes" />
                                                                        <FormControlLabel style={{ marginBottom: 0 }} value="No" control={<Radio />} label="No" />
                                                                    </RadioGroup>
                                                                    <FormHelperText>It will automatically create an account for you in Archeplay and it will create the free trial.</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            {
                                                                freeTrial === "Yes" ?
                                                                    <Grid item xs={12} style={{ padding: '0.5rem 1rem 0.5rem 1rem', borderBottom: '1px solid lightgrey' }}>
                                                                        <FormControl fullWidth>
                                                                            <label required className={typographyClasses.bold}>Password</label>
                                                                            <input type="password" placeholder="********" value={password} onChange={handlePasswordChange}></input>
                                                                            <small style={{ color: 'darkgrey', textAlign: 'justify' }}>Your password should be more than seven characters and it should contain atleast one uppercase,one lowercase,one special character and one numeric value.</small>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    :
                                                                    null
                                                            }
                                                            <Grid item xs={12} style={{ padding: '0.5rem 1rem 0.5rem 1rem' }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="api-btn"
                                                                    fullWidth
                                                                    onClick={triggerRecaptcha}
                                                                >
                                                                    Next
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        :
                                        null
                                }
                                {
                                    showCal === true && showThanks === false ?
                                        <Grid item xs={12}>
                                            <Box mb={2}>
                                                <div id="calendly-embed" style={{ minWidth: '320px', height: '1000px' }}></div>
                                            </Box>
                                        </Grid>
                                        :
                                        false
                                }
                                {
                                    showThanks === true ?
                                        <Grid item xs={12} md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mb={4}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography className={`${typographyClasses.h4} ${typographyClasses.bold}`}>Thanks for contacting Archeplay, see you soon on the demo day.</Typography>
                                                    </Box>
                                                </Box>
                                                {
                                                    freeTrial === "Yes" ?
                                                        <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                window.location.href = window.location.origin + "/#/app/signup"
                                                            }}
                                                        >
                                                            <Typography className={`${'text-design'} ${typographyClasses.bold} ${typographyClasses.h4}`}>Redirecting to trial...</Typography>
                                                        </Box>
                                                        :
                                                        <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                window.location.href = window.location.origin + "/#/app/signup"
                                                            }}
                                                        >
                                                            <Typography className={`${'text-design'} ${typographyClasses.bold} ${typographyClasses.h4}`}>Explore our Platform</Typography>
                                                        </Box>
                                                }
                                                <Box mt={3} display="flex" flexDirection="column" alignItems="center">
                                                    <Box display="flex" mb={2}>
                                                        <Typography className={`${typographyClasses.bold} ${typographyClasses.h5}`}>If any queries</Typography>
                                                    </Box>
                                                    <Box display="flex" flexDirection="column">
                                                        <Box display="flex" alignItems="center" mb={2}>
                                                            <Box mr={2} display="flex">
                                                                <MailOutlineIcon className={typographyClasses.h5} color="primary" />
                                                            </Box>
                                                            <Box display="flex">
                                                                <Typography className={`${typographyClasses.h6}`}>
                                                                    <a href="mailto:talktous@archeplay.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none',color:'black'}}>talktous@archeplay.com</a>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" mb={2}>
                                                            <Box mr={2} display="flex">
                                                                <CallIcon className={typographyClasses.h5} color="primary" />
                                                            </Box>
                                                            <Box display="flex">
                                                                <Typography className={`${typographyClasses.h6}`}>
                                                                    <a href="tel:+17326031380" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none',color:'black' }}>+17326031380</a>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" mb={2}>
                                                            <Box mr={2} display="flex">
                                                                <WhatsAppIcon className={typographyClasses.h5} color="primary" />
                                                            </Box>
                                                            <Box display="flex">
                                                                <Typography className={`${typographyClasses.h6}`}>
                                                                    <a href="https://api.whatsapp.com/send?phone=919606381617" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none',color:'black' }}>+919606381617</a>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        :
                                        null
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Footer page="index" />
            <CookieConsent />
        </div >
    )
}

export default Index;