import apidomain from './ApiDomain';
import Cognitofucntions from '../Pages/Cognito/CognitoFunctions';
import CognitoDetails from '../Pages/Cognito/CognitoDetails';

const HandleApi = {
    handleAPI: (api, method, body, headers) => {
        let token = CognitoDetails.accessToken
        let userData = sessionStorage.getItem('user') ? JSON.parse(window.atob(sessionStorage.getItem('user'))) : ''
        let apidom = apidomain.url
        if(apidom === undefined || apidom === ""){
            apidom = sessionStorage.getItem('apidomain')
        }
        if (token === '') {
            // Cognitofucntions.Signout()
        }
        else {
            let additionalHeaders = new Headers()
            additionalHeaders.append('auth', token)
            if (headers) {
                additionalHeaders.append('userid', userData.EmailId)
                // additionalHeaders.append('tenantid', userData.TenantId)
            }
            if (method === "POST" || method === "DELETE" || method === "PATCH") {
                additionalHeaders.set('Content-Type', 'application/json')
            }
            if (sessionStorage.getItem('googlesignin')) {
                if (!localStorage.getItem('aws-amplify-federatedInfo')) {
                    Cognitofucntions.Signout()
                }
                else {
                    return fetch(apidom + api, { method: method, body: body, headers: additionalHeaders })
                        // return fetch(apidom + api, { method: method, body: body })
                        .then(result => result.json())
                        .then(result => {
                            return result
                        })
                        .catch(err => {
                            return err
                        })
                }
            }
            else {
                if(body !== undefined && Object.keys(JSON.parse(body)).length > 0){
                    return fetch(apidom + api, { method: method, body: body, headers: additionalHeaders })
                        .then(result => result.json())
                        .then(result => {
                            return result
                        })
                        .catch(err => {
                            return err
                        })
                }
                else {
                    return fetch(apidom + api, { method: method, headers: additionalHeaders })
                        .then(result => result.json())
                        .then(result => {
                            return result
                        })
                        .catch(err => {
                            return err
                        })
                }
            }
        }
    }
}

export default HandleApi

// http://localhost:3000/#/app/services/serviced64941de