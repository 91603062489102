import React from 'react';

//Material UI
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.snackbar + 1,
		color: '#fff',
	},
}));

function BackdropLoader({ type, open }) {
	const classes = useStyles();

	return (
		<div>
			{
				type === "block" ?
					<CircularProgress />
					:
					null
			}
			{
				type === "page" ?
					<Backdrop className={classes.backdrop} open={open}>
						<CircularProgress color="inherit" />
					</Backdrop>
					:
					null
			}
		</div>
	);
}

// BackdropLoader.propTypes = {
//   open: Proptypes.bool.isRequired
// }

export default BackdropLoader;