import React from 'react';

//Material UI
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

export default function Info({ openAlert, severity, message, handler }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Snackbar open={openAlert} autoHideDuration={10000} onClose={handler}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert onClose={handler} severity={severity}>
					{message}
				</Alert>
			</Snackbar>
		</div>
	);
}
