import React, { useState } from 'react';

//Material UI
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

//Material Icons
import MenuIcon from '@material-ui/icons/Menu';

//Assets
import ApLogo from '../assets/images/ap-logo.png';

//Custom Component
import Cognitofunctions from '../Pages/Cognito/CognitoFunctions';
import CognitoDetails from '../Pages/Cognito/CognitoDetails';

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        background: 'white',
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'none',
        borderBottom: '1px solid lightgray'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBar: {
        // backgroundImage: 'linear-gradient(45deg, #000e1a , #1a0049 120%)',
        background: 'white',
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'none',
        borderBottom: '1px solid lightgray'
    },
    APLogo: {
        height: '33px',
        cursor: 'pointer'
    },
    List: {
        padding: 0
    },
    ListItem: {
        display: 'flex',
        justifyContent: 'center',
        // flexDirection:'column',
        padding: '0',
        '&:hover': {
            backgroundImage: 'linear-gradient(45deg, #2196F3, #00BCD4)',
            color: 'white'
        }
    },
    ListIcon: {
        color: "inherit",
        display: 'flex',
        flexDirection: 'column',
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        lineHeight: '1.2',
        // minWidth: 'auto',
        padding: "0 16px",
        '&:hover': {
            color: 'white'
        }
    },
    ListText: {
        fontSize: '0.9rem'
    }
}));

const typographyStyles = makeStyles((theme) => ({
    text: {
        fontSize: '0.9rem',
        textAlign: 'left',
        whiteSpace: "pre-line"
    },
    smallText: {
        fontSize: '0.8rem'
    },
    subtitle1: {
        fontSize: '1.1rem',
        padding: "10px",
        textAlign: 'left',
        fontWeight: 'bold'
    }
}));

const popoverStyles = makeStyles((theme) => ({
    root: {
        // padding: 16,
        width: '260px'
    }
}));


const MiniDrawer = ({showTopBar}) => {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const classes = useStyles();
    const popoverClasses = popoverStyles()
    const typographyClasses = typographyStyles()

    let userData = sessionStorage.getItem('user') ? JSON.parse(window.atob(sessionStorage.getItem('user'))) : ''



    const handleUserClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const signout = () => {
        Cognitofunctions.Signout()
    }


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar style={{ minHeight: 50, height: 50 }}>
                    <Box>
                        <img className={classes.APLogo} src={ApLogo} alt="Archeplay" />
                    </Box>
                    <Box display="flex" alignItems="center" style={{ width: '100%', height: '100%' }} justifyContent="flex-end" className="top-nav">
                        {/* <ProductBar />
                        <ServiceBar /> */}
                        <Box>
                            <Button
                                onClick={handleUserClick}
                                variant="text"
                            >
                                <MenuIcon />
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Popover
                id={id}
                className={`${popoverClasses.root} ${'user-menu'}`}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
                    <Box color="primary.contrastText" bgcolor="primary.main" display="flex" padding={1} pt={0} pb={0} alignItems="center">
                        <Box>
                            {
                                Object.keys(userData).length > 0 ?
                                    <Box p={1}>
                                        <Typography className={typographyClasses.text}>{userData.EmailId}</Typography>
                                    </Box>
                                    :
                                    null
                            }
                        </Box>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: '100%' }} p={1}>
                        <Typography className={typographyClasses.text}>{CognitoDetails.EmailId}</Typography>
                        <Box display="flex" justifyContent="space-between">
                            {/* <Box>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => {
                                        setAnchorEl(null);
                                        window.location.href = window.location.origin + "/#/user/subscriptions"
                                    }}
                                    fullWidth={false}
                                >
                                    Subscriptions
        					    </Button>
                            </Box> */}
                            <Box>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={signout}
                                    fullWidth={false}
                                >
                                    Signout
        					    </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </div>
    );
}

export default MiniDrawer