import React, { useState, useEffect } from 'react';

// Material UI 
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// Material Icons
// import CloseIcon from '@material-ui/icons/Close';

// Router
import { NavLink } from 'react-router-dom';


const CookieConsent = () => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (sessionStorage.getItem('cookie-accepted') === "yes") {
            setOpen(false)
        }
        else {
            setOpen(true)
        }
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        sessionStorage.setItem('cookie-accepted', 'yes')
        setOpen(false);
    };

    return (
        <div>
            <Snackbar
                style={{ padding: '10px', left: 0, bottom: '60px', display: open === true ? 'block' : 'none' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                transitionDuration={{
                    enter: 500
                }}
                onClose={handleClose}
                message={
                    <React.Fragment>
                        <Box>
                            <Typography style={{ fontSize: '0.9rem' }} align="justify">This website uses cookies to improve user experience. By using our website you consent to all cookies in accordance with our Privacy Policy.<NavLink to="/privacy-policy" target="_blank" style={{ color: '#2196F3' }}>Read More</NavLink></Typography>
                        </Box>
                    </React.Fragment>
                }
                action={
                    <React.Fragment>
                        <Box p={1}>
                            <Button color="primary" variant="contained" size="small" className="trigger-btn" onClick={handleClose}>
                                Got It
                            </Button>
                        </Box>
                    </React.Fragment>
                }
            />
        </div>
    );
}

export default CookieConsent;