import React, { useState } from 'react'

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';


// Material Icons
import ClearIcon from '@material-ui/icons/Clear';


const DocsModal = ({ ...props }) => {
    const [docModal, setDocModal] = useState(true)

    return (
        <Dialog open={docModal} aria-labelledby="form-dialog-title" maxWidth="sm" disableBackdropClick className="docs-code" fullWidth>
            <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={1} p={1} pr={2} pl={2} style={{ borderColor: 'lightgrey' }}>
                <Box>
                    <Typography variant="h4">{props.title}</Typography>
                </Box>
                <Box>
                    <Button onClick={() => props.clickHandler(false)} color="secondary" variant="outlined">
                        <ClearIcon />
                    </Button>
                </Box>
            </Box>
            <DialogContent>
                {
                    props.innerhtml === true ?
                        props.content !== "" ?
                            <Box p={2} border={1} style={{ width: '100%', borderColor: 'lightgrey' }} overflow="auto">
                                <div
                                    dangerouslySetInnerHTML={{ __html: props.content }}
                                >
                                </div>
                            </Box>
                            :
                            <Box p={2}>
                                <Typography>No content available for this environment.</Typography>
                            </Box>
                        :
                        null
                }
                {
                    props.innerhtml === false ?
                        <Box style={{ height: '90vh' }}>
                            <div
                                style={{height:'100%'}}
                                dangerouslySetInnerHTML={{ __html: props.content }}
                            >
                            </div>
                        </Box>
                        :
                        null
                }
            </DialogContent>
        </Dialog>
    )
}

export default DocsModal;