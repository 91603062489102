import makeStyles from '@material-ui/core/styles/makeStyles';

const TypographyStyles = makeStyles((theme) => ({
    root: {
        color: 'white'
    },
    text: {
        fontSize: '1rem',
    },
    h1: {
        fontSize: theme.typography.h1.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h2.fontSize
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.h3.fontSize
        }
    },
    h2: {
        fontSize: theme.typography.h2.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h3.fontSize
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.h4.fontSize
        }
    },
    h3: {
        fontSize: theme.typography.h3.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h4.fontSize
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.h5.fontSize
        }
    },
    h4: {
        fontSize: theme.typography.h4.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h5.fontSize
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.h6.fontSize
        }
    },
    h5: {
        fontSize: theme.typography.h5.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h6.fontSize
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: '0.9rem'
        }
    },
    h6: {
        fontSize: theme.typography.h6.fontSize,
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem'
        }
    },
    bold: {
        fontWeight: 'bold'
    },
    smallText: {
        fontSize: '0.8rem'
    }
}));

export default TypographyStyles;