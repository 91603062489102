import Amplify from 'aws-amplify'
import $ from 'jquery';
import apidomain from './ApiDomain'


// let dataJSON = {
//     "domain": "https://archeplaylabs.auth.us-east-1.amazoncognito.com",
//     "redirect_uri": "https://devlocal.archeplay.com/enterpriselabs/index.html",
//     "aws_user_pools_id": "us-east-1_xPsHImn8D",
//     "aws_user_pools_web_client_id": "47g94ojpsp2msn0lkcq1k63t5v",
//     "apidomain": "https://api.devlocal.archeplay.com",
//     "region":"us-east-1"
// }

//Devlocal Config Data
let dataJSON = {
    apidomain: "https://api.devlocal.archeplay.com",
    identityPoolId: 'us-east-1:98f6a427-f808-4b68-8ed2-5744c07b744b', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-east-1', // REQUIRED - Amazon Cognito Region
    userPoolId: 'us-east-1_kMH6aoDCq', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '4ocs19eg5o32mf3oo6b7i7t9kt', //OPTIONAL - Amazon Cognito Web Client ID
}

// let dataJSON = {
//     apidomain: "https://api.devlocal.archeplay.com",
//     // identityPoolId: 'us-east-1:98f6a427-f808-4b68-8ed2-5744c07b744b', //REQUIRED - Amazon Cognito Identity Pool ID
//     region: 'us-east-1', // REQUIRED - Amazon Cognito Region
//     userPoolId: 'us-east-1_YFqyf0yyn', //OPTIONAL - Amazon Cognito User Pool ID
//     userPoolWebClientId: 'bkfpi3t7kpick5d9nh1diq0s5', //OPTIONAL - Amazon Cognito Web Client ID
// }


// // Old Prod Config Data
// let dataJSON = {
//     apidomain: "https://api.archeplay.com",
//     userPoolId: "us-east-1_83kURAb8i",
//     region: 'us-east-1', // REQUIRED - Amazon Cognito Region
//     userPoolWebClientId: "3goui8ec57gcou6bl2c2ln1fnn",
//     // identityPoolId:"us-east-1:4e4197a6-30c4-4650-a769-6dad72271d1f"
// }

//Prod Config Data
// let dataJSON = {
//     apidomain: "https://api.archeplay.com",
//     userPoolId: "us-east-1_M0O8FmGHe",
//     region: 'us-east-1', // REQUIRED - Amazon Cognito Region
//     userPoolWebClientId: "5ga1kkiuvl9gi77eenoqj1dg6d",
//     identityPoolId:"us-east-1:31d96aee-2c03-405e-95ad-c2e84ed280c6"
// }

GetAmplifyData();


function GetAmplifyData() {
    $.ajax({
        url: window.location.origin+"/app/data.json",
        crossDomain:true,
        success: function (result) {
            let data = {
                "identityPoolId": result.identityPoolId,
                region: result.region,
                "userPoolId": result.userPoolId,
                "userPoolWebClientId": result.userPoolWebClientId,
                "apidomain": result.apidomain,
            }
            apidomain.url = data.apidomain
            sessionStorage.setItem('cognitoauth', window.btoa(JSON.stringify(data)));
            sessionStorage.setItem('apidomain', data.apidomain);
            Amplify.configure({ Auth: data });
        },
        error: function (err) {

        }
    })
}
// apidomain.url = dataJSON.apidomain
// sessionStorage.setItem('cognitoauth', window.btoa(JSON.stringify(dataJSON)));
// sessionStorage.setItem('apidomain', dataJSON.apidomain);
// Amplify.configure({
//     Auth: dataJSON
// });


export default GetAmplifyData;