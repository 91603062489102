import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';

// devlocal
const amplifyConfig = {
    Auth: {
        // identityPoolId: 'us-east-1:44419e6d-1b56-4f91-b75d-532e2c8e7ba2',
        identityPoolId: 'us-east-1:5272c532-9805-422b-ae4d-d7a530415142',
        region: 'us-east-1'
    }
}

// prod
// const amplifyConfig = {
//     Auth: {
//         identityPoolId: 'us-east-1:4e4197a6-30c4-4650-a769-6dad72271d1f',
//         region: 'us-east-1'
//     }
// }
//Initialize Amplify
Auth.configure(amplifyConfig);

// devlocal
const analyticsConfig = {
    AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '5c6a00888b7e476e8c5212363def7fcf',
        // Amazon service region
        region: 'us-east-1',
        mandatorySignIn: false,
    }
}

// Prod
// const analyticsConfig = {
//     AWSPinpoint: {
//         // Amazon Pinpoint App Client ID
//         appId: 'db82db1fa74f42a78f9de7a998b25103',
//         // Amazon service region
//         region: 'us-east-1',
//         mandatorySignIn: false,
//     }
// }

Analytics.configure(analyticsConfig)


const PageViewTrack = (trackingData) => {
    Analytics.autoTrack('pageView', {
        enable: true,
        type: 'SPA',
        // attributes: trackingData.attributes,
        getUrl: () => {
            return trackingData.url;
        }
    });
}


const TrackEvents = (trackingData) => {
    Analytics.record(trackingData);
}



export default { PageViewTrack, TrackEvents }