import React from 'react';

// Material UI
import makeStyles from '@material-ui/core/styles/makeStyles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TimelineDot from '@material-ui/lab/TimelineDot';

// Images
import LowCode from '../../assets/images/Low-code.png';
import APITesting from '../../assets/images/API_Testing.png';
import CloudDeploy from '../../assets/images/cloud_deployment.png';
import DesignData from '../../assets/images/design_data.png';
import MultipleVersions from '../../assets/images/multiple_api_versions.png';

// Styles 
import TypographyStyles from '../../assets/styles/TypographySyles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    root: {
        backgroundColor: '#d0d0d0',
    },
    images: {
        width: '95%'
    },
    dot: {
        boxShadow: 'none',
        width: window.innerWidth >= 768 ? '80px' : '80px',
        height: window.innerWidth >= 768 ? '80px' : '80px',
        backgroundColor: 'white',
        padding: '0',
        margin: '0',
    }
}));

const timelinedata = [
    {
        "heading": "Low-Code API Development Environment",
        "content": "Catalogue of Pre-Created API design for Multiple Cloud, Language and Platforms.",
        "bgcolor": "#89cbff",
        "image": MultipleVersions,
    },
    {
        "heading": "Live Deploy your API",
        "image": DesignData,
        "bgcolor": "#27d9c8",
        "content": "Design your Databases Out of the box."
    },
    {
        "heading": "Live Test your API",
        "image": APITesting,
        "bgcolor": "#89cbff",
        "content": "Auto-Generate all the Create, Read, Update and Delete operation API of your Database indexes."
    },
    {
        "heading": "Micro Services API Template Catalog",
        "image": LowCode,
        "bgcolor": "#89cbff",
        "content": "Customize the API Code with Pre-Created Snippet for all common use cases and Custom Codes of your choice."
    },
    {
        "heading": "Auto Generate APIs ",
        "image": APITesting,
        "bgcolor": "#89cbff",
        "content": "Live Deploy and Live Test the API out of box in the platform, Deploy and testing starts as soon as you update the code."
    },
    {
        "heading": "Customize code with Snippets",
        "image": MultipleVersions,
        "bgcolor": "#89cbff",
        "content": "Autogenerate Test Cases for the API, and Enhance your Test data."
    },
    {
        "heading": "Production Deployment Automation Code",
        "image": APITesting,
        "bgcolor": "#89cbff",
        "content": "Live Test the API with Test cases every time you change the code."
    },
    {
        "heading": "Production Deployment Automation Code",
        "image": CloudDeploy,
        "bgcolor": "#89cbff",
        "content": "Generate the Deployment Automation Scripts for you deploy the API with single Command in Production on Cloud of your choice."
    }
]

const CustomizedTimeline = ({ block }) => {
    const classes = useStyles();
    const typographyClasses = TypographyStyles()

    return (
        <Box>
            <Timeline align={window.innerWidth >= 768 ? "alternate" : 'left'}>
                {
                    timelinedata.map((tline, index) => {
                        return (
                            <TimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineDot className={classes.dot}>
                                        <Box style={{ width: '100%', borderRadius: '50%' }} display="flex" p={2} alignItems="center" justifyContent="center" boxShadow={3}>
                                            <img alt={tline.heading + index} src={tline.image} className={classes.images} />
                                        </Box>
                                    </TimelineDot>
                                    {
                                        index + 1 !== timelinedata.length ?
                                            <TimelineConnector className={classes.root} />
                                            :
                                            null
                                    }
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box display="flex" mb={3} style={{ backgroundImage: 'linear-gradient(45deg, #e2e6ff, #beebff)' }} flexDirection="column" color="#626262" minHeight={100} alignItems="center" justifyContent="center">
                                        <Box p={2} pt={1}>
                                            <Typography align="justify" className={typographyClasses.text}>{tline.content}</Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })
                }
            </Timeline>
        </Box>
    );
}

export default CustomizedTimeline;
