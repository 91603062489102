import React, { useEffect } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

// Custom components
import Header from '../../Components/TopNav';
import Footer from '../../Components/Footer/index';
// import Pinpoint from '../../Components/AWSPinpoint';
import TypographyStyles from '../../assets/styles/TypographySyles';

// Google Analytics
import ReactGA from 'react-ga';

// React Helmet
import Helmet from "react-helmet/es/Helmet";


const containerStyles = makeStyles((theme) => ({
    root: {
        padding: '0',
        maxWidth: '110rem',
        display: 'flex',
        marginTop: '64px',
        width: '100%'
    }
}))


const PrivacyPolicy = () => {
    const containerClasses = containerStyles()
    const typographyClasses = TypographyStyles()

    useEffect(() => {
        initializeReactGA()
        // Pinpoint.PageViewTrack({ url: '/privacy-policy' })
    }, [])

    function initializeReactGA() {
        // devlocal
        ReactGA.initialize('UA-174472554-3');
        // prod
        // ReactGA.initialize('UA-174472554-2'); 
        ReactGA.pageview('/privacy-policy');
    }

    return (
        <div>
            <Header.MiniDrawer page="index" />
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <Container className={containerClasses.root}>
                <Box style={{ padding: '1.5rem 2rem', maxWidth: '110rem', margin: 'auto', width: window.innerWidth <= 768 ? '100%' : '90%' }}>
                    <Box mb={2}>
                        <Typography className={`${typographyClasses.bold} ${typographyClasses.h4} ${'text-design'}`} align="left">Privacy Policy</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="left">
                        <Box display="flex" flexDirection="column" alignItems="left" mb={2}>
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h6}`} align="left">What Data we get?</Typography>
                            </Box>
                            <Box ml={2}>
                                <Typography className={typographyClasses.text} align="justify">We collect certain data from you directly, like information you enter yourself, data about participation in the environment used ,and no data of your work inside the environment is stored by us.</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="left" mb={2}>
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h6}`} align="left">1. Data You Provide to Us</Typography>
                            </Box>
                            <Box mb={1} ml={2}>
                                <Typography className={typographyClasses.text} align="justify">We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect.</Typography>
                            </Box>
                            <Box ml={2} mb={1}>
                                <Box mb={1}>
                                    <Typography className={`${typographyClasses.bold} ${typographyClasses.text}`} align="justify">When you create an account and use the Services, including through a third-party platform, we collect any data you provide directly, including:</Typography>
                                </Box>
                                <Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Account Data</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">In order to use certain features (like using an environment), you need to create a user account. When you create or update your account, we collect and store the data you provide, like your email address, password, gender, and date of birth, mobile, email ID, LinkedIn URL and assign you a unique identifying number (“Account Data”).</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Profile Data</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">You can also choose to provide profile information like a photo, headline, website link, social media profiles, or other data. Your Profile Data will be publicly viewable by others.</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Shared Content</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">Parts of the Services let you interact with other users or share content publicly, including by posting reviews on an environment page, asking or answering questions, sending messages to users, or posting photos or other work you upload. Such shared content may be publicly viewable by others depending on where it is posted.</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Environment Data</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">When you enroll in and use the environment, we collect certain data including which cloud and which type of environments you are using but we do not store any information of the data used inside the environment.</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Payment Data</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">If you make purchases, we collect certain data about your purchase (such as your name and zip code) as necessary to process your order. You must provide certain payment and billing data directly to our payment processing partners, including your name, credit card information, billing address, and zip code. For security, Archeplay does not collect or store sensitive cardholder data, such as full credit card numbers or card authentication data.</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Data About Your Accounts on Other Services</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">We may obtain certain information through your social media or other online accounts if they are connected to your Archeplay account. If you login to Archeplay via Facebook, LinkedIN or another third-party platform or service, we ask for your permission to access certain information about that other account. For example, depending on the platform or service we may collect your name, profile picture, account ID number, login email address, location, physical location of your access devices, gender, birthday, and list of friends or contacts.Those platforms and services make information available to us through their APIs. The information we receive depends on what information you (via your privacy settings) or the platform or service decide to give us. If you access or use our Services through a third-party platform or service, or click on any third-party links, the collection, use, and sharing of your data will also be subject to the privacy policies and other agreements of that third party.
</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Sweepstakes, Promotions, and Surveys</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">We may invite you to complete a survey or participate in a promotion (like a contest, sweepstakes, or challenge), either through the Services or a third-party platform. If you participate, we will collect and store the data you provide as part of participating, such as your name, email address, date of birth, or phone number. That data is subject to this Privacy Policy unless otherwise stated in the official rules of the promotion or in another privacy policy. The data collected will be used to administer the promotion or survey, including for notifying winners and distributing rewards. To receive a reward, you may be required to allow us to post some of your information publicly (like on a winner’s page). Where we use a third-party platform to administer a survey or promotion, the third party’s privacy policy will apply.</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Communications and Support</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">If you contact us for support or to report a problem or concern (regardless of whether you have created an account), we collect and store your contact information, messages, and other data about you like your name, email address, location, operating system, IP address, and any other data you provide or that we collect through automated means (which we cover below). We use this data to respond to you and research your question or concern, in accordance with this Privacy Policy.</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h6}`} align="left">2. Cookies and Data Collection Tools</Typography>
                            </Box>
                            <Box ml={2}>
                                <Box mb={1}>
                                    <Typography className={typographyClasses.text} align="justify">Archeplay and service providers acting on our behalf (like Google Analytics and third party advertisers) use server log files and automated data collection tools like cookies, tags, scripts, customized links, device or browser fingerprints, and web beacons (together, “Data Collection Tools”) when you access and use the Services. These Data Collection Tools automatically track and collect certain System Data and Usage Data when you use the Services. In some cases, we tie data gathered through those Data Collection Tools to other data that we collect as described in this Privacy Policy.</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Typography className={typographyClasses.text} align="justify">We use cookies (small files that websites send to your device to uniquely identify your browser or device or to store data in your browser) for things like analyzing your use of the Services, personalizing your experience, making it easier to log into the Services, and recognizing you when you return. We use web beacons (small objects that allow us to measure the actions of visitors and users using the Services) for things like identifying whether a page was visited, identifying whether an email was opened, and advertising more efficiently by excluding current users from certain promotional messages or identifying the source of a new mobile app download.</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Typography className={`${typographyClasses.bold} ${typographyClasses.text}`} align="justify">Archeplay uses the following types of cookies:</Typography>
                                </Box>
                                <Box mb={1} ml={2}>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Preferences</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">Cookies that remember data about your browser and preferred settings that affect the appearance and behavior of the Services (like your preferred language).</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Security</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">Cookies used to enable you to log in and access the Services; protect against fraudulent logins; and help detect and prevent abuse or unauthorized use of your account.</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Functional</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">Cookies that store functional settings (like the volume level you set for video playback).</Typography>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Box mb={1}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.text} ${'text-design'}`} align="left">Session State</Typography>
                                        </Box>
                                        <Box mb={1} ml={2}>
                                            <Typography className={typographyClasses.text} align="justify">Cookies that track your interactions with the Services to help us improve the Services and your browsing experience, remember your login details, and enable processing of your course purchases. These are strictly necessary for the Services to work properly, so if you disable them then certain functionalities will break or be unavailable.</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box mb={1}>
                                    <Typography className={typographyClasses.text} align="justify">You can set your web browser to alert you about attempts to place cookies on your computer, limit the types of cookies you allow, or refuse cookies altogether. If you do, you may not be able to use some or all features of the Services, and your experience may be different or less functional.</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Typography className={typographyClasses.text} align="justify">Some of the third-party partners who provide certain features on our site may also use Local Storage Objects (also known as flash cookies or LSOs) to collect and store data.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Footer page="index" />
        </div>
    )
}

export default PrivacyPolicy;