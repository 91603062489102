import React, { useState, useEffect } from 'react';

// Assets
import APLogo from '../../assets/images/ap-logo.png'

// Material UI
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

// Custom Components
import Cognitofunctions from './CognitoFunctions';
import Loader from '../../Components/Loader';
import Alert from '../../Components/Alert';

// React Helmet
import Helmet from "react-helmet/es/Helmet";

// Router
import { useHistory } from "react-router-dom";
import { NavLink } from 'react-router-dom';

const imgStyles = makeStyles((theme) => ({
    logo: {
        height: '32px'
    }
}))

const containerStyles = makeStyles((theme) => ({
    root: {
        padding: '2rem',
        backgroundColor: 'white',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

const boxStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100rem',
        width: '50%',
        [theme.breakpoints.down("sm")]: {
            width: '75%'
        },
        [theme.breakpoints.down("xs")]: {
            width: '95%'
        }
    },
    wrapper: {
        margin: 'auto',
        padding: '2rem',
    }
}))

const formStyles = makeStyles((theme) => ({
    form: {
        marginTop: '2rem',
        width: '100%'
    },
    inputError: {
        padding: '5px',
        height: '2rem',
    },
    label: {
        textAlign: 'left',
        margin: '0 0 10px 0 !important',
    },
    common: {
        width: '100%',
        margin: '10px 0',
        borderRadius: 0
    }
}));

const commonStyles = makeStyles((theme) => ({
    wrapper: {
        width: 'calc(100% - 64px)',
        padding: '1rem',
        margin: 'auto'
    }
}))

const ForgotPasswordIndex = () => {
    const [verificationCode, setVerificationCode] = useState('')
    const [openVerficationCode, setOpenVerficationCode] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loader, setLoader] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverrity] = useState('')
    const [message, setMessage] = useState('')
    const [confPass, setConfPass] = useState('')

    const imgClasses = imgStyles()
    const containerClasses = containerStyles()
    const boxClasses = boxStyles()
    const formClasses = formStyles()
    const commonClasses = commonStyles()
    let history = useHistory();

    useEffect(() => {
        setOpenVerficationCode(false)
    }, [])

    const handleAlert = () => {
        setOpenAlert(false)
    }

    // const validateEmail = (value) => {
    //     let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    //     if (reg.test(value) === false) {
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }
    // }
    // const validatePassword = (value) => {

    // }

    const forgotPasswordTrigger = () => {
        setLoader(true)
        Cognitofunctions.ForgotPasswordTrigger({ "email": email }, function (success, data) {
            if (success) {
                setMessage("Verification code sent to your email.")
                setSeverrity('success')
                setOpenAlert(true)
                setLoader(false)
                setOpenVerficationCode(true)
            }
            else {
                setMessage(data.message)
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
            }
        })
    }

    const forgotPasswordSubmit = () => {
        setLoader(true)
        if (password === confPass) {
            Cognitofunctions.ForgotPasswordConfirm({ "email": email, "code": verificationCode, "password": password }, function (success, data) {
                if (success) {
                    setMessage("Password Changed successfully.")
                    setSeverrity('success')
                    setOpenAlert(true)
                    setTimeout(function () {
                        // window.location.href = window.location.origin + "/login"
                        // window.location.href = window.location.origin
                        history.push('/app/login')
                    }, 1000)
                }
                else {
                    setMessage(data.message)
                    setSeverrity('error')
                    setOpenAlert(true)
                    setLoader(false)
                }
            })
        }
        else {
            setMessage('Password and Confirm Password did not match')
            setSeverrity('error')
            setOpenAlert(true)
            setLoader(false)
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleVerficationCodeChange = (event) => {
        setVerificationCode(event.target.value)
    }

    const handleConfPasswordChange = (event) => {
        setConfPass(event.target.value)
    }

    const handleEnterClick = (clickHandler, event) => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault()
            clickHandler()
        }
    }

    return (
        <div>
            <Loader open={loader} type="page" />
            <Helmet>
                <title>Archeplay - Reset Password</title>
            </Helmet>
            <Alert openAlert={openAlert} severity={severity} message={message} handler={handleAlert} />
            <Container className={containerClasses.root}>
                <Box className={boxClasses.root} boxShadow={2} bgcolor="#fcfcfc">
                    <Box className={boxClasses.wrapper}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex">
                                <img className={imgClasses.logo} src={APLogo} alt="Archeplay" />
                            </Box>
                            <Box display="flex">
                                <h2 style={{ margin: '7px' }}>Forgot Password</h2>
                            </Box>
                        </Box>
                        <Box>
                            <div className={commonClasses.wrapper}>
                                <form className={formClasses.form} autoComplete="off">
                                    <FormControl className={formClasses.common}>
                                        <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-email">Email Address</label>
                                        <input type="email" placeholder="example@domain.com" onChange={handleEmailChange} onKeyPress={handleEnterClick.bind(this, forgotPasswordTrigger)}></input>
                                    </FormControl>
                                    {
                                        openVerficationCode === true ?
                                            <Box>
                                                <FormControl className={formClasses.common}>
                                                    <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-pass">Password</label>
                                                    <input type="password" placeholder="********" onChange={handlePasswordChange}></input>
                                                    <small style={{ color: 'darkgrey', textAlign: 'justify' }}>
                                                        Your password should be more than seven characters and it should contain
                                                        atleast one uppercase,one lowercase,one special
                                                        character and one numeric value.
                                                </small>
                                                </FormControl>
                                                <FormControl className={formClasses.common}>
                                                    <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-pass">Confirm Password</label>
                                                    <input type="password" placeholder="********" onChange={handleConfPasswordChange} autoComplete="off"></input>
                                                    <small style={{ color: 'darkgrey', textAlign: 'left' }}>Confirm Password should match with the above password</small>
                                                </FormControl>
                                                <FormControl className={formClasses.common}>
                                                    <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-pass">Verification Code</label>
                                                    <input type="text" placeholder="Enter 6 digit verification code" onChange={handleVerficationCodeChange} onKeyPress={handleEnterClick.bind(this, forgotPasswordSubmit)}></input>
                                                </FormControl>
                                            </Box>
                                            :
                                            null
                                    }
                                    <Button
                                        className={formClasses.common}
                                        color="primary"
                                        variant="contained"
                                        onClick={openVerficationCode === true ? forgotPasswordSubmit : forgotPasswordTrigger}
                                    >
                                        submit
                            </Button>
                                </form>
                                <Box display="flex" justifyContent="space-around" alignItems="center">
                                    <NavLink to="/app/login" style={{ textDecoration: 'none' }}>
                                        Take me to Login
                                    </NavLink>
                                </Box>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default ForgotPasswordIndex