import React, { useState, useEffect } from 'react';

// Material UI
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

// Material Icons
import LaunchIcon from '@material-ui/icons/Launch';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

// Custom Components
import HandleApi from '../../Utils/HandleApi';
import TopNav from '../../Components/AppNav';
import Cognitofunctions from '../Cognito/CognitoFunctions';
import Loader from '../../Components/Loader'
import Alert from '../../Components/Alert'
import Footer from '../../Components/Footer/index';
import Confirm from '../../Components/Confirm';

// React Helmet
import Helmet from "react-helmet/es/Helmet";

// assets
import AppImage from '../../assets/images/app_image.png'

// Styles
import ContainerStyles from '../../assets/styles/ContainerStyles'
import TypographyStyles from '../../assets/styles/TypographySyles'


const User = () => {
    const [loader, setLoader] = useState(true)
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverrity] = useState('')
    const [message, setMessage] = useState('')
    const [instanceStatus, setInstanceStatus] = useState('')
    const [instanceDNS, setInstanceDNS] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const [confApiData, setConfApiData] = useState({})
    const [isMachineReady, setIsMachineReady] = useState(false)
    const [timeoutflag, setTimeOutFlag] = useState(false)
    const [dialog, setDialog] = useState(false)

    const containerClasses = ContainerStyles()
    const typographyClasses = TypographyStyles()

    let userData = sessionStorage.getItem('user') ? JSON.parse(window.atob(sessionStorage.getItem('user'))) : ''
    let intervalflag = false, interval = ""

    useEffect(() => {
        checkrole()
    }, [])

    const checkrole = () => {
        Cognitofunctions.CurrentUser(function (success, data) {
            if (success) {
                let apidata1 = {
                    path: "/trial/describe",
                    body: {
                        EmailId: userData.EmailId
                    },
                    headers: true
                }

                manageAPIs(apidata1)
            }
            else {
                setMessage('You are not authorized to access this page.')
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
                setTimeout(function () {
                    window.location.href = window.location.origin + "/#/app/login"
                    window.location.reload()
                }, 1000)
            }
        })
    }

    const manageAPIs = (apidata) => {
        HandleApi.handleAPI(apidata.path, "POST", JSON.stringify(apidata.body), apidata.headers)
            .then(result => {
                if (result.hasOwnProperty('message') && result.message !== "") {
                    setMessage(result.message)
                    setSeverrity('info')
                    setOpenAlert(true)
                }
                if (result.hasOwnProperty('statusCode') && result.statusCode === 200) {
                    setLoader(false)
                    if (apidata.path === "/trial/deploy" || apidata.path === "/trial/describe" || apidata.path === "/trial/delete") {
                        if (result.body.hasOwnProperty('status') && result.body.status) {
                            setInstanceStatus(result.body.status)
                            if (result.body.status === "deploying") {
                                setTimeOutFlag(false)
                                if (!intervalflag) {
                                    intervalflag = true
                                    interval = setInterval(function () {
                                        let apidata1 = {
                                            path: "/trial/describe",
                                            body: {
                                                EmailId: userData.EmailId
                                            },
                                        }
                                        manageAPIs(apidata1)
                                    }, 30000)
                                }
                            }
                            else {
                                if (result.body.status === "running") {
                                    if (!timeoutflag) {
                                        setTimeOutFlag(true)
                                        checkMachineDNS({
                                            path: result.body.InstancePublicDns
                                        })
                                    }
                                    else {
                                        setIsMachineReady(true)
                                    }
                                }
                                clearInterval(interval)
                                intervalflag = false
                            }
                        }
                        else {
                            setIsMachineReady(false)
                            setInstanceStatus('')
                            clearInterval(interval)
                            intervalflag = false
                        }
                        if (result.body.hasOwnProperty('InstancePublicDns') && result.body.InstancePublicDns) {
                            setInstanceDNS(result.body.InstancePublicDns)
                        }
                        else {
                            setInstanceDNS('')
                        }
                    }
                }
                else {
                    clearInterval(interval)
                    intervalflag = false
                    setInstanceStatus('')
                    if (result.hasOwnProperty('errorMessage')) {
                        setMessage(result.errorMessage)
                    }
                    else {
                        setMessage("Some internal error.Please try after some time.")
                    }
                    setSeverrity('error')
                    setOpenAlert(true)
                    setLoader(false)
                }
            })
            .catch(err => {
                setMessage("Some internal error!. please try after some time")
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
            })
    }

    const checkMachineDNS = (apidata) => {
        window.setTimeout(function () {
            setIsMachineReady(true)
            setInstanceStatus("running")
            setMessage('Your free trial is running, click on below link to get the trial')
            setSeverrity('success')
            setOpenAlert(true)
            window.setTimeout(function () {
                window.open("http://" + apidata.path, "_blank")
            }, 1000)
        }, 60000)
    }

    const handleActions = (apidata) => {
        if (apidata.Action === 'redirect') {
            window.open(apidata.url, '_blank')
        }
        else if (apidata.Action === "refresh") {
            setTimeOutFlag(false)
            setLoader(true)
            let apidata1 = {
                path: "/trial/describe",
                body: {
                    EmailId: userData.EmailId
                },
            }
            manageAPIs(apidata1)
        }
        else if (apidata.Action === "confirmation") {
            setConfApiData(apidata['apidata'])
            setConfirmation(apidata.confirmText)
        }
        else {
            setLoader(true)
            manageAPIs(apidata)
        }
    }

    const handleConfirmation = (key) => {
        setConfirmation('')
        if (key) {
            setLoader(true)
            handleActions(confApiData)
            // console.log(confApiData)
            setConfApiData({})
        }
        else {
            setConfApiData({})
        }
    }

    const handleAlert = () => {
        setOpenAlert(false)
    }

    function copyText(key) {
        var tempIp = document.createElement('input')
        tempIp.value = key
        tempIp.style.position = 'fixed'
        tempIp.setAttribute('id', 'temp-copy')
        var root = document.getElementById('root')
        root.appendChild(tempIp)
        let copytext = document.getElementById('temp-copy')
        copytext.select()
        document.execCommand("copy");
        setMessage("Text copied")
        setSeverrity('success')
        setOpenAlert(true)
        tempIp.remove()
    }

    return (
        <div>
            <TopNav />
            <Helmet>
                <title>User</title>
            </Helmet>
            <Loader open={loader} type="page" />
            <Alert openAlert={openAlert} severity={severity} message={message} handler={handleAlert} />
            <Container className={containerClasses.root}>
                <Box display="flex" flexDirection="column" style={{ width: '100%', height: "calc(100vh - 81px)" }} p={2}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            variant="text"
                            color="primary"
                            onClick={handleActions.bind(this, { "Action": "refresh" })}
                        >
                            <RefreshIcon className={typographyClasses.h5} />
                        </Button>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" style={{ height: '100%' }} flexDirection="column" >
                        {
                            instanceStatus === "deploying" || (isMachineReady === false && instanceStatus !== "") ?
                                <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" mb={3}>
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                                        <Box display="flex" alignItems="center" mr={2}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.h5}`}>Preparing your environment</Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" mr={2} color="green">
                                            {
                                                instanceStatus === "deploying" ?
                                                    <CircularProgress size={20} color="inherit" />
                                                    :
                                                    <CheckCircleOutlineIcon className={typographyClasses.h4} color="inherit" />
                                            }
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Box display="flex" alignItems="center" mr={2}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.h5}`}>Installing Archeplay application</Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" mr={2} color="green">
                                            {
                                                isMachineReady === false ?
                                                    <CircularProgress size={20} color="inherit" />
                                                    :
                                                    <CheckCircleOutlineIcon className={typographyClasses.h4} color="inherit" />
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                :
                                null
                        }
                        {
                            instanceStatus === "" || instanceStatus === "terminated" ?
                                <Box display="flex" alignItems="center" justifyContent="center" style={{ backgroundImage: 'linear-gradient(45deg, #0c1b6c, #023660)', cursor: 'pointer' }} p={2} mb={3} pl={6} pr={6}
                                    onClick={() => {
                                        setInstanceStatus('deploying')
                                        let apidata = {
                                            path: "/trial/deploy",
                                            body: {
                                                EmailId: userData.EmailId
                                            },
                                        }
                                        manageAPIs(apidata)
                                    }}
                                >
                                    <Typography className={`${typographyClasses.h5} ${typographyClasses.bold} ${typographyClasses.root}`}>
                                        Start Trial
                                    </Typography>
                                </Box>
                                :
                                null
                        }
                        {
                            instanceStatus === "running" && isMachineReady === true ?
                                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" mb={3}>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Box display="flex" mr={2}>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.h5}`}>If not redirecting click here to access the trial</Typography>
                                        </Box>
                                        <Box mb={1} display="flex" style={{ cursor: 'pointer' }} onClick={() => {
                                            window.open("http://" + instanceDNS, "_blank")
                                        }}>
                                            <LaunchIcon className={`${typographyClasses.bold} ${typographyClasses.h4}`} color="primary" />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography className={typographyClasses.smallText}>(or)</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Box style={{ cursor: 'pointer' }} onClick={() => {
                                            let strtocp = "http://" + instanceDNS
                                            copyText(strtocp)
                                        }}>
                                            <Typography color="primary" className={`${typographyClasses.bold} ${typographyClasses.h6}`}>Click here to copy&nbsp;&nbsp;</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={`${typographyClasses.bold} ${typographyClasses.h6}`}>and paste it in a new tab</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                :
                                null
                        }
                        {
                            instanceStatus === "running" && isMachineReady === true ?
                                <Box display="flex" alignItems="center" justifyContent="center" style={{ backgroundImage: 'linear-gradient(45deg, rgb(108, 12, 12), rgb(96, 2, 20))', cursor: 'pointer' }} p={2} pl={6} pr={6}
                                    onClick={() => {
                                        handleActions({
                                            Action: "confirmation",
                                            confirmText: "Are you sure you want to Stop your trial?",
                                            apidata: {
                                                path: "/trial/delete",
                                                body: {
                                                    EmailId: userData.EmailId
                                                },
                                            }
                                        })
                                    }}
                                >
                                    <Typography className={`${typographyClasses.h5} ${typographyClasses.bold} ${typographyClasses.root}`}>
                                        Stop Trial
                                    </Typography>
                                </Box>
                                :
                                null
                        }
                    </Box>
                    <Box mb={5} style={{ padding: window.innerWidth > 768 ? '1.5rem' : '0', maxWidth: '110rem', margin: 'auto', width: '100%' }} bgcolor="white">
                        <Box mb={3} position="relative">
                            <Box mb={1}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h3} ${'text-design'} ${typographyClasses.uppercase}`} align="center">Watch the Demo Video</Typography>
                            </Box>
                            <Box style={{ width: window.innerWidth > 768 ? '85%' : '90%', borderColor: 'black' }} borderRadius={10} m="auto" border={window.innerWidth > 768 ? 30 : 15} boxShadow={10}>
                                <img src={AppImage} style={{ width: '100%' }} alt="Archeplay Low Code Platform" />
                            </Box>
                            <Box style={{ width: window.innerWidth > 768 ? '95%' : '97%', borderColor: '#4b4b4b' }} bgcolor="#4b4b4b" borderRadius={10} border={window.innerWidth > 768 ? 15 : 5} m="auto">
                            </Box>
                            <Box position="absolute" bgcolor="white" display="flex" alignItems="center" style={{ top: '50%', left: '50%', cursor: 'pointer', borderRadius: '50%' }} boxShadow={5} className="play-button" onClick={() => setDialog(true)}>
                                <PlayCircleFilledIcon className={`${typographyClasses.h1}`} color="primary" />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" flexDirection="column">
                            <Box display="flex" mb={2}>
                                <Typography className={`${typographyClasses.bold} ${typographyClasses.h4} ${'text-design'}`}>Want to buy our Product ?</Typography>
                            </Box>
                            <Box p={1.2} pl={6} pr={6} style={{ cursor: 'pointer', backgroundImage: "linear-gradient(45deg, #2196F3, #3F51B5)" }} mb={3} onClick={() => {
                                window.location.href = window.location.origin + "/#/contact-us"
                            }}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Typography className={`${typographyClasses.bold} ${typographyClasses.h6} ${typographyClasses.root}`}>CONTACT US</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Dialog open={dialog} onClose={() => setDialog(false)} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth>
                <DialogTitle id="form-dialog-title">Archeplay Demo Video</DialogTitle>
                <DialogContent>
                    <Box>
                        <iframe style={{ height: window.innerWidth > 768 ? '75vh' : '500px' }} src="https://www.youtube.com/embed/x172xWe-VrU" title="Archeplay Demo Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialog(false)} color="secondary" variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {
                confirmation !== '' ?
                    <Confirm confirmText={confirmation} clickHandler={handleConfirmation} />
                    :
                    null
            }
            <Footer page="app" />
        </div>
    )
}

export default User;