import React from 'react';

//Assets
import ApLogo from '../../assets/images/ap-logo.png';

//Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Styles
// import TypographyStyles from '../assets/styles/TypographySyles';

//Router
// import { NavLink } from 'react-router-dom';

// Custom Components
import Layout from './Layout';


const FooterData = [
    {
        "heading": "Contact",
        "content": {
            "Email": {
                "extlink": "mailto:talktous@archeplay.com",
                "value": "talktous@archeplay.com"
            },
            "Call": {
                "extlink": "tel:+17326031380",
                "value": "+17326031380"
            },
            "WhatsApp": {
                "extlink": "https://api.whatsapp.com/send?phone=919606381617",
                "value": "+919606381617"
            }
        }
    }
]


const Footer = ({ page }) => {
    // const typographyClasses = TypographyStyles()

    return (
        <Box>
            {
                page === "index" ?
                    <Box style={{ width: '100%', backgroundImage: 'linear-gradient(45deg, black, #140037)' }}>
                        <Box style={{ width: '100%', maxWidth: '110rem', padding: '1rem 2rem', margin: 'auto' }} >
                            <Box>
                                <Box mb={2}>
                                    <Box>
                                        <Grid container>
                                            {
                                                FooterData.map((ft, index) => {
                                                    return (
                                                        <Grid item xs={12} md={6} style={{ padding: '5px' }} key={index}>
                                                            <Layout heading={ft.heading} content={ft.content}/>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </Box>
                                <Divider style={{ backgroundColor: '#828282', height: '2px' }} />
                                <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} flexWrap="wrap">
                                    <Box display="flex" m={1}>
                                        <img style={{ height: '35px' }} src={ApLogo} alt="Archeplay" />
                                    </Box>
                                    <Box m={1}>
                                        <Typography style={{ fontSize: '0.9rem', color: 'white' }}>&copy;Archeplay {new Date().getFullYear()}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    :
                    null
            }
            {
                page === "app" ?
                    <Box style={{ width: '100%', backgroundImage: 'linear-gradient(45deg, black, #140037)' }} position="fixed" bottom={0} height={30} zIndex={1200}>
                        <Box style={{ width: '100%', height: '100%' }}>
                            <Box display="flex" justifyContent="space-between" style={{ width: '100%', height: '100%' }}>
                                <Box style={{ height: '100%' }} display="flex" alignItems="center" pl={2} pr={2}>
                                    <Typography style={{ fontSize: '0.7rem', color: '#b9b9b9' }} align="center">&copy;Archeplay {new Date().getFullYear()}</Typography>
                                </Box>
                                {/* <Box pl={2} pr={2} display="flex" alignItems="center">
                                    <NavLink to="/privacy-policy" target="_blank" style={{ textDecoration: 'none' }}>
                                        <Typography className={`${typographyClasses.root}`} style={{ fontSize: '0.7rem' }} align="center">
                                            Privacy Policy
                                        </Typography>
                                    </NavLink>
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                    :
                    null
            }
        </Box>
    )
}

export default Footer;