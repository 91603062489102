import '../../Utils/AmplifyData';
import Auth from '@aws-amplify/auth';
import CognitoDetails from './CognitoDetails';
// import GoogleCred from './GoogleSigninDetails';
// import GoogleAPIScript from './LoadGoogleAPIScript';

let refreshSession = ''

function Signin(apidata, asyncfuntion) {
    Auth.signIn({
        username: apidata.email,
        password: apidata.password
    }).then(user => {
        asyncfuntion(true, user)
    }).catch(err => {
        asyncfuntion(false, err)
    })
}

function Signup(apidata, asyncfuntion) {
    Auth.signUp({
        username: apidata.email,
        password: apidata.password,
        attributes: {
            email: apidata.email,
        },
    })
        .then((data) => {
            asyncfuntion(true, data)
        })
        .catch((err) => {
            asyncfuntion(false, err)
        });
}

function Confirmsignup(apidata, asyncfuntion) {
    Auth.confirmSignUp(apidata.email, apidata.code)
        .then(result => {
            asyncfuntion(true, result)
        })
        .catch(err => {
            asyncfuntion(false, err)
        })
}

function Resendcode(apidata, asyncfuntion) {
    Auth.resendSignUp(apidata.email)
        .then(result => {
            asyncfuntion(true, result)
        })
        .catch(err => {
            asyncfuntion(false, err)
        })
}

function CurrentUser(asyncfuntion) {
    Auth.currentAuthenticatedUser({
    }).then(user => {
        // sessionStorage.setItem('cognito-user', user.attributes.email);
        // sessionStorage.setItem('cognito-access', user.signInUserSession.accessToken.jwtToken)
        CognitoDetails.email = user.attributes.email
        CognitoDetails.accessToken = user.signInUserSession.idToken.jwtToken
        RetrieveSession()
        asyncfuntion(true, user)
    }).catch(err => {
        asyncfuntion(false, err)
    })
}

function RetrieveSession() {
    Auth.currentSession()
        .then(data => {
            // sessionStorage.setItem('cognito-access', data.accessToken.jwtToken);
            // sessionStorage.setItem('cognito-id', data.idToken.jwtToken);
            CognitoDetails.accessToken = data.idToken.jwtToken
            clearInterval(refreshSession);
            refreshSession = setInterval(function () {
                console.log('Session refreshed')
                RetrieveSession()
            }, (data.accessToken.payload.exp - Math.round(new Date().getTime() / 1000)) * 1000);
        })
        .catch(err => {
            // console.log(err)
        });
}

function ForgotPasswordTrigger(apidata, asyncfuntion) {
    Auth.forgotPassword(apidata.email)
        .then(response => {
            asyncfuntion(true, response)
        })
        .catch(err => {
            asyncfuntion(false, err)
        })
}

function ForgotPasswordConfirm(apidata, asyncfuntion) {
    Auth.forgotPasswordSubmit(apidata.email, apidata.code, apidata.password)
        .then(response => {
            asyncfuntion(true, response)
        })
        .catch(err => {
            asyncfuntion(false, err)
        })
}

function Completenewpassword(apidata, asyncfuntion) {
    Auth.completeNewPassword(apidata.currentuser, apidata.password)
        .then(result => {
            asyncfuntion(true, result)
        })
        .catch(err => {
            asyncfuntion(false, err)
        })
}

function Signout(key) {
    for (let i = 0; i < sessionStorage.length;) {
        if (sessionStorage.key(i) === "cognitoauth" || sessionStorage.key(i) === "apidomain" || sessionStorage.key(i) === "cookie-accepted" || sessionStorage.key(i) === "user_attrs") {
            i = i + 1;
            continue;
        }
        else {
            sessionStorage.removeItem(sessionStorage.key(i))
        }
    }
    Auth.signOut()
        .then(result => {

        })
        .catch(err => {

        })

    // GoogleCred['GoogleCred'] = {}
    // if(window.gapi && window.gapi.auth2){
    //     window.gapi.auth2.getAuthInstance().signOut()
    // }
    // else{
    //     GoogleAPIScript.CheckGoogleAPI('signout')
    // }

    setTimeout(function () {
        // window.location.href = window.location.origin
        window.location.href = window.location.origin
    }, 1000)
}

function GetGoogleCredentials(asyncfuntion) {
    let federatedInfo = localStorage.getItem('aws-amplify-federatedInfo');
    if (federatedInfo) {
        CognitoDetails.accessToken = JSON.parse(federatedInfo)['token']
        CognitoDetails.email = JSON.parse(federatedInfo)['user']['email']
        asyncfuntion(true)
    }
    else {
        asyncfuntion(false)
    }
}

export default { Signin, CurrentUser, RetrieveSession, Signup, ForgotPasswordTrigger, ForgotPasswordConfirm, Confirmsignup, Completenewpassword, Resendcode, Signout, GetGoogleCredentials };