// Custom Components
import HandleAPI from './HandleApi';
import CognitoDetails from '../Pages/Cognito/CognitoDetails';
import Cognitofunctions from '../Pages/Cognito/CognitoFunctions';



function GetRole(asyncfunction) {
    HandleAPI.handleAPI("/users/users", "POST", JSON.stringify({ "EmailId": CognitoDetails.email, "TenantId": sessionStorage.getItem('TenantId'), "Action": "getroles" }))
        .then(result => {
            if (result.status === 200) {
                sessionStorage.setItem('roles', window.btoa(JSON.stringify(result.body.Roles)))
                asyncfunction(true, result.body.Roles)
            }
            else {
                asyncfunction(false, result)
            }
        })
        .catch(err => {
            asyncfunction(false, err)
        })
}

function CheckRole(key, asyncfunction) {
    Cognitofunctions.CurrentUser(function (success, data) {
        if (success) {
            if (sessionStorage.getItem('roles')) {
                if ((JSON.parse(window.atob(sessionStorage.getItem('roles')))).indexOf(key) === -1) {
                    window.location.href = window.location.origin + "/#/app/login"
                }
                else {
                    asyncfunction(true)
                }
            }
            else {
                GetRole(function (success, data) {
                    if (data.indexOf(key) === -1) {
                        // asyncfunction(false)
                        window.location.href = window.location.origin + "/#/app/login"
                    }
                    else {
                        asyncfunction(true)
                    }
                })
            }
        }
        else {
            window.location.href = window.location.origin + "/#/app/login"
            // Cognitofunctions.GetGoogleCredentials(function (success) {
            //     if (success) {
            //         if (sessionStorage.getItem('roles')) {
            //             if ((JSON.parse(window.atob(sessionStorage.getItem('roles')))).indexOf(key) === -1) {
            //                 window.location.href = window.location.origin + "/#/app/login"
            //             }
            //             else {
            //                 asyncfunction(true)
            //             }
            //         }
            //         else {
            //             GetRole(function (success, data) {
            //                 if (data.indexOf(key) === -1) {
            //                     // asyncfunction(false)
            //                     window.location.href = window.location.origin + "/#/app/login"
            //                 }
            //                 else {
            //                     asyncfunction(true)
            //                 }
            //             })
            //         }
            //     }
            //     else {
            //         window.location.href = window.location.origin + "/#/app/login"
            //     }
            // })
        }
    })
}

function GetUser(asyncfunction) {
    let apidata = {
        "EmailId": CognitoDetails.email,
        // "TenantId": sessionStorage.getItem('TenantId')
    }
    HandleAPI.handleAPI("/archeuser/user", "POST", JSON.stringify(apidata))
        .then(result => {
            asyncfunction(true, result)
        })
        .catch(err => {
            asyncfunction(false, err)
        })
}

function GetUserTenants(EmailId, asyncfunction) {
    let apidata = {
        EmailId: EmailId,
        Action: "getusertenants"
    }
    HandleAPI.handleAPI("/users/users", "POST", JSON.stringify(apidata))
        .then(result => {
            asyncfunction(true, result)
        })
        .catch(err => {
            asyncfunction(false, err)
        })
}

export default { GetRole, CheckRole, GetUser, GetUserTenants }