import React, { useState, useEffect } from 'react';

// Custom Components
import Cognitofunctions from './CognitoFunctions';
import UserRoles from '../../Utils/UserRoles';
import CognitoDetails from './CognitoDetails';
import Loader from '../../Components/Loader';
import Alert from '../../Components/Alert';
// import GoogleSignin from './GoogleSign';

// Assets
import APLogo from '../../assets/images/ap-logo.png'

// Material UI
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

// Router
import { NavLink } from 'react-router-dom';
// import { useHistory } from "react-router-dom";

// React Helmet
import Helmet from "react-helmet/es/Helmet";


const imgStyles = makeStyles((theme) => ({
    logo: {
        height: '32px'
    }
}))

const containerStyles = makeStyles((theme) => ({
    root: {
        padding: '2rem',
        backgroundColor: 'white',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

const boxStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100rem',
        width: '50%',
        [theme.breakpoints.down("sm")]: {
            width: '75%'
        },
        [theme.breakpoints.down("xs")]: {
            width: '95%'
        }
    },
    wrapper: {
        margin: 'auto',
        padding: '1rem 2rem',
    }
}))

const formStyles = makeStyles((theme) => ({
    form: {
        marginTop: '2rem',
        width: '100%'
    },
    inputError: {
        padding: '5px',
        height: '2rem',
    },
    label: {
        textAlign: 'left',
        margin: '0 0 10px 0 !important',
    },
    common: {
        width: '100%',
        margin: '10px 0',
        borderRadius: 0
    }
}));

const commonStyles = makeStyles((theme) => ({
    wrapper: {
        width: 'calc(100% - 64px)',
        padding: '1rem',
        margin: 'auto'
    }
}))

const LoginIndex = () => {
    const [verificationCode, setVerificationCode] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confPass, setConfPass] = useState('')
    const [openLogin, setOpenLogin] = useState(false)
    const [openNewPassword, setOpenNewPassword] = useState(false)
    const [openNotValidated, setOpenNotValidated] = useState(false)
    const [openVerficationCode, setOpenVerficationCode] = useState(false)
    const [chooseTenants, setChooseTenants] = useState(false)
    const [tenantList, setTenantList] = useState([])
    const [currentUser, setCurrentUser] = useState('')
    const [loader, setLoader] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverrity] = useState('')
    const [message, setMessage] = useState('')
    const imgClasses = imgStyles()
    const containerClasses = containerStyles()
    const boxClasses = boxStyles()
    const formClasses = formStyles()
    const commonClasses = commonStyles()

    // let history = useHistory();

    // ReactGA.initialize('UA-156108870-1');

    useEffect(() => {
        // const ga = window.gapi && window.gapi.auth2 ?
        //     window.gapi.auth2.getAuthInstance() :
        //     null;
        // if (!ga) {
        //     createScript();
        // }
        let init = setInterval(function () {
            if (sessionStorage.getItem('cognitoauth')) {
                getCurrentUser()
                clearInterval(init)
            }
        }, 100)
        setOpenLogin(true)
        if (sessionStorage.getItem('FreeTrialEnabled') && sessionStorage.getItem('FreeTrialEnabled') === "Yes") {
            setMessage('Thanks for contacting Archeplay!!. Meanwhile you can enjoy your Free trial created for you by login, until one of our experts get back to you.')
            setSeverrity('success')
            setOpenAlert(true)
        }
    }, [])

    // function initGapi(g) {
    //     g.load('auth2', function () {
    //         g.auth2.init({
    //             client_id: '38263492319-j1p2qgsvt2cr412jjfb6hstdjkslg81e.apps.googleusercontent.com',
    //             // authorized scopes
    //             scope: 'profile email openid'
    //         }).then(res => {
    //             sessionStorage.setItem('cookie-enabled', "true")
    //         }).catch(err => {
    //             if (err.hasOwnProperty('error') && err.error === "idpiframe_initialization_failed") {
    //                 sessionStorage.setItem('cookie-enabled', "false")
    //             }
    //             setLoader(false)
    //         })
    //         getCurrentUser()
    //     });
    // }

    // function createScript() {
    //     // load the Google SDK
    //     const script = document.createElement('script');
    //     script.src = 'https://apis.google.com/js/platform.js';
    //     script.async = true;
    //     script.onload = getGapi();
    //     document.body.appendChild(script);
    // }

    // function getGapi() {
    //     // init the Google SDK client
    //     let ginterval = setInterval(function () {
    //         const g = window.gapi
    //         if (g !== undefined) {
    //             initGapi(g)
    //             clearInterval(ginterval)
    //         }
    //     }, 1000)
    // }

    const handleAlert = () => {
        setOpenAlert(false)
    }

    const getCurrentUser = () => {
        setLoader(true)
        Cognitofunctions.CurrentUser(function (success, data) {
            if (success) {
                setCurrentUser(data)
                // Getrole();
                // GetTenants(data.attributes.email);
                GetUser();
                // setLoader(false)
            }
            else {
                setLoader(false)
                window.location.href = window.location.origin + "/#/app/login"
                // Cognitofunctions.GetGoogleCredentials(function (success) {
                //     if (success) {
                //         GetUser();
                //     }
                //     else {
                //         setLoader(false)
                //     }
                // })
                // Cognitofunctions.GetCognitoIdentityCredentials()
            }
        })
    }

    const Login = () => {
        setLoader(true)
        Cognitofunctions.Signin({ "email": email, "password": password }, function (success, data) {
            if (success) {
                if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setCurrentUser(data)
                    setOpenLogin(false)
                    setLoader(false)
                    setOpenNewPassword(true)
                }
                else {
                    getCurrentUser()
                }
            }
            else {
                setLoader(false)
                if (data.code !== 'UserNotConfirmedException') {
                    setMessage(data.message)
                    setSeverrity('error')
                    setOpenAlert(true)
                }
                // if (data.code === "UserNotConfirmedException") {
                //     // setOpenLogin(false)
                //     // setOpenNotValidated(true)
                //     getCurrentUser()
                // }
            }
        })
    }

    const NewPassword = () => {
        setLoader(true)
        if (password === confPass) {
            Cognitofunctions.Completenewpassword({ "currentuser": currentUser, "password": password }, function (success, data) {
                if (success) {
                    setMessage("Password Changed successfully")
                    setSeverrity('success')
                    setOpenAlert(true)
                    getCurrentUser()
                }
                else {
                    setMessage(data.message)
                    setSeverrity('error')
                    setOpenAlert(true)
                    setLoader(false)
                }
            })
        }
        else {
            setMessage('Password and Confirm Password did not match')
            setSeverrity('error')
            setOpenAlert(true)
            setLoader(false)
        }
    }

    const ResendCode = () => {
        setLoader(true)
        Cognitofunctions.Resendcode({ 'email': email }, function (success, data) {
            if (success) {
                setLoader(false)
                setOpenVerficationCode(true)
                setVerificationCode('')
                setMessage("Code resent successfully")
                setSeverrity('success')
                setOpenAlert(true)
            }
            else {
                setMessage(data.message)
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
            }
        })
    }

    const ConfirmSignUp = () => {
        setLoader(true)
        Cognitofunctions.Confirmsignup({ 'email': email, 'code': verificationCode }, function (success, data) {
            if (success) {
                setOpenVerficationCode(false)
                setVerificationCode('')
                setOpenNotValidated(false)
                setOpenLogin(true)
                setMessage("Account verified successfully.")
                setSeverrity('success')
                setOpenAlert(true)
                setLoader(false)
            }
            else {
                setMessage(data.message)
                setSeverrity('error')
                setOpenAlert(true)
                setLoader(false)
            }
        })
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleConfPasswordChange = (event) => {
        setConfPass(event.target.value)
    }

    const handleVerficationCodeChange = (event) => {
        setVerificationCode(event.target.value)
    }

    const GetUser = () => {
        UserRoles.GetUser(function (success, result) {
            if (success) {
                if (result.hasOwnProperty('statusCode') && result.statusCode === 200) {
                    CognitoDetails.User = result.body
                    sessionStorage.setItem('user', window.btoa(JSON.stringify(result.body)))
                    window.location.href = window.location.origin + "/#/user"
                    // if (sessionStorage.getItem('subscribe')) {
                    //     window.location.href = window.location.origin + "/#/user/addsubscriptions?planId=" + sessionStorage.getItem('subscribe')
                    // }
                    // else {
                    //     if (result.body.Trial === "NotDone") {
                    //     }
                    //     else {
                    //         window.location.href = window.location.origin + "/#/app/products"
                    //     }
                    // }
                }
                else {
                    setMessage(result.errorMessage)
                    setSeverrity('error')
                    setOpenAlert(true)
                    setLoader(false)
                    if (result.errorMessage === "User Not Present in Company") {
                        Cognitofunctions.Signout()
                    }
                }
            }
            else {

            }
        })
    }

    const GetTenants = (EmailId) => {
        UserRoles.GetUserTenants(EmailId, function (success, result) {
            if (success) {
                if (result.hasOwnProperty('statusCode') && result.statusCode === 200) {
                    CognitoDetails.TenantsList = result.body.Tenants
                    if (result.body.Tenants.length > 0 && result.body.Tenants.length === 1) {
                        sessionStorage.setItem('Tenants', window.btoa(JSON.stringify(result.body.Tenants)))
                        sessionStorage.setItem('TenantId', result.body.Tenants[0].TenantId)
                        GetUser();
                    }
                    else if (result.body.Tenants.length > 0) {
                        sessionStorage.setItem('Tenants', window.btoa(JSON.stringify(result.body.Tenants)))
                        GetUser();
                    }
                    else {
                        GetUser();
                    }
                }
                else {
                    setMessage(result.errorMessage)
                    setSeverrity('error')
                    setOpenAlert(true)
                    setLoader(false)
                    console.log("something")
                    if (result.errorMessage === "User Not Present in Company") {
                        Cognitofunctions.Signout()
                    }
                }
            }
            else {

            }
        })
    }

    // const Getrole = () => {
    //     UserRoles.GetRole(function (success, data) {
    //         if (success) {
    //             if (sessionStorage.getItem('subscribe')) {
    //                 window.location.href = window.location.origin + "/#/user/addsubscriptions?planId=" + sessionStorage.getItem('subscribe')
    //             }
    //             else {
    //                 window.location.href = window.location.origin + "/#/app/products"
    //             }
    //         }
    //     })
    // }

    const handleEnterClick = (clickHandler, event) => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault()
            clickHandler()
        }
    }

    // const handleTenantSelect = (TenantId) => {
    //     setLoader(true)
    //     sessionStorage.setItem('TenantId', TenantId)
    //     GetUser()
    // }

    return (
        <div>
            <Helmet>
                <title>Login to Archeplay</title>
            </Helmet>
            <Loader open={loader} type="page" />
            <Alert openAlert={openAlert} severity={severity} message={message} handler={handleAlert} />
            <Container className={containerClasses.root}>
                <Box className={boxClasses.root} boxShadow={2} bgcolor="#fcfcfc">
                    <Box className={boxClasses.wrapper}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex">
                                <img className={imgClasses.logo} src={APLogo} alt="Archeplay" />
                            </Box>
                            <Box>
                                {
                                    openLogin === true ?
                                        <h2 style={{ margin: '7px' }}>Login</h2>
                                        :
                                        null
                                }
                                {
                                    openNewPassword === true ?
                                        <h2>Complete New Password</h2>
                                        :
                                        null
                                }
                                {
                                    openNotValidated === true ?
                                        <h2>Verify User</h2>
                                        :
                                        null
                                }
                                {
                                    chooseTenants === true ?
                                        <h2>Choose Tenant</h2>
                                        :
                                        null
                                }
                            </Box>
                        </Box>

                        <Box>
                            {
                                openLogin === true ?
                                    <div className={commonClasses.wrapper}>
                                        <form className={formClasses.form} autoComplete="off">
                                            <FormControl className={formClasses.common}>
                                                <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-email">Email Address</label>
                                                <input type="email" placeholder="example@domain.com" onChange={handleEmailChange} autoComplete="off"></input>
                                            </FormControl>
                                            <FormControl className={formClasses.common}>
                                                <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-pass">Password</label>
                                                <input type="password" placeholder="********" onChange={handlePasswordChange} autoComplete="off" onKeyPress={handleEnterClick.bind(this, Login)}></input>
                                                <small style={{ color: 'darkgrey', textAlign: 'left' }}>Your password should be more than seven characters and it should contain
                                                atleast one uppercase,one lowercase,one special
                                        character and one numeric value.</small>
                                            </FormControl>
                                            <Button
                                                className={formClasses.common}
                                                color="primary"
                                                variant="contained"
                                                onClick={Login}
                                            >
                                                Login
                                        </Button>
                                            {/* <Box mt={1} mb={2} display="flex" alignItems="center" justifyContent="center">
                                            or
                                        </Box> */}
                                            {/* <Button
                                            className={formClasses.common}
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                Cognitofunctions.FederatedSignin()
                                            }}
                                        >
                                            Google
                                        </Button> */}
                                            {/* <GoogleSignin /> */}
                                        </form>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                            <NavLink to="/app/login/forgot-password" style={{ textDecoration: 'none' }}>
                                                Forgot Password?
                                        </NavLink>
                                            <NavLink to="/app/signup" style={{ textDecoration: 'none' }}>
                                                Don't have an account? Register
                                        </NavLink>
                                        </Box>
                                    </div>
                                    :
                                    null
                            }
                            {
                                openNewPassword === true ?
                                    <div>
                                        <form className={formClasses.form} autoComplete="off">
                                            <FormControl className={formClasses.common}>
                                                <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-pass">New Password</label>
                                                <input type="password" placeholder="********" onChange={handlePasswordChange} autoComplete="off"></input>
                                                <small style={{ color: 'darkgrey', textAlign: 'left' }}>Your password should be more than seven characters and it should contain
                                                atleast one uppercase,one lowercase,one special
                                        character and one numeric value.</small>
                                            </FormControl>
                                            <FormControl className={formClasses.common}>
                                                <label required className={`${formClasses.label} ${formClasses.common}`} htmlFor="login-pass">Confirm Password</label>
                                                <input type="password" placeholder="********" onChange={handleConfPasswordChange} onKeyPress={handleEnterClick.bind(this, NewPassword)} autoComplete="off"></input>
                                                <small style={{ color: 'darkgrey', textAlign: 'left' }}>Confirm Password should match with the above password</small>
                                            </FormControl>
                                            <Button
                                                className={formClasses.common}
                                                color="primary"
                                                variant="contained"
                                                onClick={NewPassword}
                                            >
                                                Submit
                                    </Button>
                                        </form>
                                    </div>
                                    :
                                    null
                            }
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default LoginIndex