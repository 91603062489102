import makeStyles from '@material-ui/core/styles/makeStyles';

const ContainerStyles = makeStyles((theme) => ({
    root: {
        padding: '0px',
        maxWidth: 'calc(100% - 0px)',
        margin: 'auto',
        display: 'flex',
        marginTop: '50px',
        // marginLeft: '60px',
        // marginBottom: '40px'
    }
}))

export default ContainerStyles;