import React from 'react'

// Styles
import TypographyStyles from '../../../assets/styles/TypographySyles';

const WordSwap = () => {
    const typographyClasses = TypographyStyles()

    return (
        <h4 className={`${"wordCarousel"} ${typographyClasses.h4} ${typographyClasses.bold}`}>
            {/* <span>You can&nbsp;&nbsp; </span> */}
            <div>
                <ul className="flip4">
                    <li id="swap-1">You can Autogenerate Production Deployment Code for API</li>
                    <li id="swap-2">You can Live Deploy and Test API</li>
                    <li id="swap-3">You can Customize Code for API</li>
                    <li id="swap-4">You can Auto Generate Code for API</li>
                    <li id="swap-5">You can Design API</li>
                </ul>
            </div>
            {/* <span>&nbsp;&nbsp;API</span> */}
        </h4>
    )
}

export default WordSwap;